import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  useTheme,
  TextField,
  OutlinedInput,
  Grid,
  IconButton,
  Typography,
  Chip,
  Divider,
} from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { DataGrid } from "@mui/x-data-grid";
import { playCorrectSound, playIncorrectSound } from "utils";
import {
  GetLatestRate,
  GetRateList,
  PatchRate,
  StoreRate,
} from "api/currencyExchangeRate";
import { isMobile } from "react-device-detect";
import moment from "moment";

const CurrencyExchangeRate = () => {
  const theme = useTheme();

  const [USD, setUSD] = useState(0);
  const [LAK, setLAK] = useState(0);
  const [remark, setRemark] = useState("");
  const [rows, setRows] = useState([]);
  const [rate, setRate] = useState(null);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  function clearForm() {
    setUSD(0);
    setLAK(0);
    setRemark("");
  }

  const formSubmittedHandler = (e) => {
    e.preventDefault();

    // Validate LAK
    if (!LAK || LAK === 0 || LAK === undefined) {
      playIncorrectSound();
      Swal.fire({
        title: "Warning!",
        icon: "warning",
        text: "Please provide a valid LAK value.",
        showConfirmButton: true,
      }).then(() => {
        const lakInput = document.getElementById("LAK");
        lakInput.select();
        lakInput.focus();
      });
      return;
    }

    // Validate USD
    if (!USD || USD === 0 || USD === undefined) {
      playIncorrectSound();
      Swal.fire({
        title: "Warning!",
        icon: "warning",
        text: "Please provide a valid USD value.",
        showConfirmButton: true,
      }).then(() => {
        const usdInput = document.getElementById("USD");
        usdInput.select();
        usdInput.focus();
      });
      return;
    }

    StoreRate(USD, LAK, remark).then((res) => {
      console.log(res);
      if (res.status === 200) {
        clearForm();
        playCorrectSound();
        Swal.fire({
          title: "Created!",
          icon: "success",
          text: `Currency Exchange Rate has been created`,
          timer: 1500,
        });
        refreshRateList();
      } else if (res.status === 400) {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
          showConfirmButton: true,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          icon: "error",
          text: res.message,
          showConfirmButton: true,
        });
      }
    });
  };

  const buttonDisableEnableHandler = (value) => {
    console.log(value);
    const { _id, USD, LAK, remark, validflag } = value;
    console.log(_id, USD, LAK, remark, validflag);
    PatchRate(_id, USD, LAK, remark, !validflag).then((res) => {
      if (res.status === 200) refreshRateList();
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "LAK",
      headerName: "ກີບ / LAK",
      flex: 2,
    },
    {
      field: "USD",
      headerName: "ໂດລາ / USD",
      flex: 2,
    },
    {
      field: "remark",
      headerName: "remark",
      flex: 3,
      hide: isMobile,
    },

    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 3,
      hide: isMobile,
      valueGetter: (params) =>
        moment(params.row.updatedAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 3,
      valueGetter: (params) =>
        moment(params.row.createdAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "validflag",
      headerName: "Validflag",
      flex: 1.5,
      renderCell: (cellValues) => {
        const activeCount = rows.filter((row) => row.validflag).length;
        const isDisabled = activeCount === 1 && cellValues.row.validflag;

        return cellValues.row.validflag ? (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={() => buttonDisableEnableHandler(cellValues.row)}
            disabled={isDisabled} // Disable if necessary
          >
            <ToggleOnIcon color="secondary" />
          </IconButton>
        ) : (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={() => buttonDisableEnableHandler(cellValues.row)}
          >
            <ToggleOffIcon />
          </IconButton>
        );
      },
    },    
  ];

  function refreshRateList() {
    setIsLoading(true);
    GetLatestRate().then((res) => {
      if (res.status === 200) {
        setRate(res.data);
      }
    });
    GetRateList(page, pageSize, sort).then((res) => {
      if (res.status === 200) {
        setRows(res.data.rates);
        setTotalCount(res.data.totalCount);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    refreshRateList();
  }, [page, pageSize, sort]);

  useEffect(() => {
    refreshRateList();
  }, []);
  return (
    <form onSubmit={formSubmittedHandler}>
      <Grid container spacing={1}>
        {/* Left side */}
        <Grid item xl={8} md={12} sm={12}>
          <Grid marginTop={1} container spacing={1.5}>
            <Grid item xl={12} md={12} xs={12}>
              <Typography
                variant="h5"
                sx={{ color: theme.palette.secondary[200] }}
              >
                Current Exchage Rate / ອັດຕາແລກປ່ຽນປັດຈຸບັນແມ່ນ
              </Typography>
            </Grid>
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth disabled>
                <Chip
                  label={
                    rate &&
                    `${(rate.LAK * 1000).toLocaleString()}LAK = 1,000THB`
                  }
                  variant="outlined"
                  color="secondary"
                  sx={{ fontSize: 14, height: 53 }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth disabled>
                <Chip
                  label={
                    rate && ` 100USD = ${(rate.USD * 100).toLocaleString()}THB`
                  }
                  variant="outlined"
                  color="secondary"
                  sx={{ fontSize: 14, height: 53 }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <InputLabel>LAK</InputLabel>
                <OutlinedInput
                  type="number"
                  id="LAK"
                  label="LAK"
                  value={LAK}
                  onChange={(e) => {
                    setLAK(e.target.value);
                  }}
                  required
                  inputProps={{ min: 500, max: 800 }} // Set your desired min and max values here
                />
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <InputLabel>USD</InputLabel>
                <OutlinedInput
                  type="number"
                  id="USD"
                  label="USD"
                  value={USD}
                  onChange={(e) => {
                    setUSD(e.target.value);
                  }}
                  required
                  inputProps={{ min: 25, max: 50 }} // Set your desired min and max values here
                />
              </FormControl>
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={remark}
                  multiline
                  maxRows={5}
                  label="Remark"
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  size="large"
                >
                  Add new rate
                </Button>
              </FormControl>
            </Grid>

            <Grid
              item
              xl={12}
              md={12}
              xs={12}
              height={"55vh"}
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                  borderTop: "none",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
                },
              }}
            >
              <DataGrid
                loading={isLoading}
                getRowId={(row) => row._id}
                rows={rows || []}
                columns={columns}
                rowCount={totalCount ? totalCount : 0}
                rowsPerPageOptions={[20, 50, 100]}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                sortingMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={(newSortModel) => setSort(...newSortModel)}
                // components={{ Toolbar: DataGridCustomToolbar }}
                // componentsProps={{
                //   toolbar: { searchInput, setSearchInput, setSearch },
                // }}
              ></DataGrid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Box mt={2}></Box>
      </Box>
    </form>
  );
};

export default CurrencyExchangeRate;
