import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  IconButton,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

const ForwardAddressDialog = ({ open, onClose, onSave }) => {
  const initialState = {
    id: uuidv4(),
    logisticCompany: "",
    branch: "",
    description: "",
    isDefault: true,
  };

  const [address, setAddress] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!address.logisticCompany) tempErrors.logisticCompany = "Required";
    if (!address.branch) tempErrors.branch = "Required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      onSave(address);
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setAddress(initialState);
      setErrors({});
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Add a New Forward Address
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid mt={1} container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={Boolean(errors.logisticCompany)}
            >
              <InputLabel>Logistic Company</InputLabel>
              <Select
                required
                label="Logistic Company"
                name="logisticCompany"
                value={address.logisticCompany}
                onChange={handleChange}
              >
                <MenuItem value="HAL Express">HAL Express</MenuItem>
                <MenuItem value="Mixay Express">Mixay Express</MenuItem>
                <MenuItem value="Anousith Express">Anousith Express</MenuItem>
              </Select>
              {errors.logisticCompany && (
                <p style={{ color: "red" }}>{errors.logisticCompany}</p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Branch"
              name="branch"
              value={address.branch}
              onChange={handleChange}
              variant="outlined"
              error={Boolean(errors.branch)}
              helperText={errors.branch}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={address.description}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={address.isDefault}
                  onChange={handleChange}
                  name="isDefault"
                  color="secondary"
                />
              }
              label="Default ກຳໜົດເປັນທີ່ຢູ່ຫຼັກ"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box margin={2} width={1000}>
          <Button
            fullWidth
            onClick={handleSave}
            color="secondary"
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ForwardAddressDialog;
