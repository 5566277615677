import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import TabsNavigator from "components/TabsNavigator";
import NewDeliver from "./NewDeliver";
import HomeDelivery from "./homeDelivery";
import Forward from "./forward";

const Packages = () => {
  const tabItems = [
    {
      text: "Self-pickup",
      component: <NewDeliver />,
    },
    { text: "Forward", component: <Forward /> },
    {
      text: "Home Delivery",
      component: <HomeDelivery />,
    },
  ];

  return (
    <Box m="0 1rem">
      <Header title="Delivery" />
      <TabsNavigator tabItems={tabItems} />
    </Box>
  );
};

export default Packages;
