import React, { useEffect, useState } from "react";
import Header from "components/Header";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import CameraAltIcon from "@mui/icons-material/CameraAlt";

import moment from "moment-timezone";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FlexBetween from "components/FlexBetween";
import { useTheme } from "@emotion/react";
import {
  GetPackageDetailByTracking,
  GetPackagesByStationAndDate,
  PatchShippingFee,
  scanPackage,
} from "api/packages";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import { GetStationDetailById } from "api/stations";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridCustomToolbarClientMode } from "components/DataGridCustomToolbar";
import {
  getScanText,
  playAlertSound,
  playBabySneezeSound,
  playCorrectSound,
  playIncorrectSound,
  playPoliceWhistleSound,
  playReadyBeebSound,
  playYahooSound,
  selectAndFocus,
} from "utils";
import { user, defaultStation } from "localStorage";
import Scanner from "components/Scanner";
import { isMobile } from "react-device-detect";
import { GetRouteDetail } from "api/routes";
import { isNullOrUndefined } from "html5-qrcode/esm/core";
import PackageNewDialog from "components/PackageNewDialog";

const Scan = () => {
  const stationList = user().stations;
  const _defaultStation = defaultStation();
  const theme = useTheme();
  const [tracking, setTracking] = useState("");
  const [selectedStation, setSelectedStation] = useState(_defaultStation);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [rows, setRows] = useState(null);
  const [successCount, setSuccessCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const _user = user();
  const isAgentUser = _user.role === "agent";

  // Data for front-end
  const [filteredRows, setFilteredRows] = useState(null);

  // const [inputValue, setInputValue] = useState('');

  // const isNonMobile = useMediaQuery("(min-width: 600px)");

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  // const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [isInputByCameraScanner, setIsInputByCameraScanner] = useState(false);

  function RefreshPackagesList(from, to, station) {
    GetPackagesByStationAndDate(from, to, station).then((res) => {
      if (res.status === 200) {
        setRows(res.data.packages);
      } else {
        console.log(res.message);
      }
    });
  }

  useEffect(() => {
    if (editDialogOpen) playPoliceWhistleSound();
  }, [editDialogOpen]);

  useEffect(() => {
    let newFromDate = moment(fromDate).format("YYYY-MM-DD");
    let newToDate = moment(toDate).format("YYYY-MM-DD");
    // console.log(`From: ${newFromDate} - To: ${newToDate}`);
    if (selectedStation) {
      RefreshPackagesList(newFromDate, newToDate, selectedStation.id);
    }
    selectAndFocus("tracking");
  }, [fromDate, toDate, selectedStation, successCount]);

  const scanPackageSubmited = (tracking) => {
    let actionType = "scan";
    scanPackage(getScanText(tracking), selectedStation.id, actionType).then(
      (res) => {
        if (res && res.status === 200) {
          if (
            selectedStation.id === "VTE" &&
            res.data.package &&
            (res.data.package.routeId === "VTE-THK" ||
              res.data.package.routeId === "VTE-DKC")
          ) {
            playBabySneezeSound(); // to indicate that the scanned item need to be sent to THK branch
          } else {
            playCorrectSound(); // to indicate that the scanned item need to be sent to PHT branch
          }
          setTracking("");
          setSuccessCount(successCount + 1);
          Swal.fire({
            icon: "success",
            title: `Success!`,
            text: `ສະແກນພັດສະດຸສຳເລັດແລ້ວ!`,
            timer: 500,
          });
          if (isInputByCameraScanner) {
            setOpen(true);
            setIsInputByCameraScanner(false);
          }
        } else if (res.status === 202) {
          // console.log(selectedStation);
          if (!selectedStation.isSortStation) {
            playIncorrectSound();
            Swal.fire({
              title: res.data.status,
              icon: "warning",
              text: res.data.message,
              timer: 2000,
            });
            if (isInputByCameraScanner) {
              setOpen(true);
              setIsInputByCameraScanner(false);
            } else {
              selectAndFocus("tracking");
            }
            return;
          }
          // In case data not found
          // playAlertSound();

          setEditDialogOpen(true);
        } else {
          playIncorrectSound();
          Swal.fire({
            title: res.data.status,
            icon: "error",
            text: res.data.message,
            timer: 2000,
          });
          if (isInputByCameraScanner) {
            setOpen(true);
            setIsInputByCameraScanner(false);
          } else {
            selectAndFocus("tracking");
          }
        }
      }
    );
  };

  const handleFormSubmit = () => {
    if (open) {
      setIsInputByCameraScanner(true);
      setOpen(false);
    }

    GetPackageDetailByTracking(getScanText(tracking)).then((res) => {
      var packageData = res.data;
      //If it's a sortstation check more details
      if (res.status !== 200 && !selectedStation.isSortStation) {
        playAlertSound();
        Swal.fire({
          title: "ບໍ່ພົບຂໍ້ມູນໃນລະບົບ!",
          icon: "warning",
          text: `ກະລຸນາກວດສອບຄືນອິກເທື່ອ!`,
          showConfirmButton: true,
        });
      } else if (selectedStation.isSortStation) {
        //Ask user to input shipping fee if it's a COD package and Shipping fee is 0
        if (
          res.status === 200 &&
          (packageData.isCOD || packageData.isSubCustomer) &&
          (isNullOrUndefined(packageData.shippingFee) ||
            packageData.shippingFee === 0)
        ) {
          GetRouteDetail(packageData.routeId).then((res) => {
            if (
              res.status === 200 &&
              res.data.isFanshine &&
              ((packageData.isSubCustomer === true &&
                packageData.agentId !== "") ||
                parseInt(packageData.customer.level) > 1)
            ) {
              playYahooSound();
            } else {
              playReadyBeebSound();
            }
          });

          let customerInfo = `${packageData.customer.name} - ${packageData.customer.whatsapp}`;
          let inputValue = "";
          Swal.fire({
            title: "ກະລຸນາລະບຸຄ່າບໍລິການ",
            text: customerInfo,
            input: "number",
            inputAttributes: {
              style:
                "display: block; margin: 20px auto; text-align: center; width: 150px; height: 50px; padding: 10px; font-size: 30px;",
            },
            // inputValue,
            preConfirm: () => {
              inputValue = Swal.getInput().value;
            },
            inputValidator: (value) => {
              if (!value) {
                return "ຕ້ອງລະບຸຄ່າບໍລິການກ່ອນ!";
              } else if (isNaN(value)) {
                return "Please enter a valid number!";
              } else if (value <= 0) {
                return "ຄ່າບໍລິການຕ້ອງຫຼາຍກວ່າ 0 !";
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              PatchShippingFee(
                packageData._id,
                packageData.tracking,
                packageData.orderId,
                packageData.description,
                packageData.remark,
                packageData.amount,
                packageData.quantity,
                inputValue
              ).then((res) => {
                if (res.status === 200) {
                  scanPackageSubmited(tracking);
                } else {
                  Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: `Update package failed, please check in console log!`,
                    showConfirmButton: true,
                  });
                  console.log(res);
                  return;
                }
              });
              // console.log("Input Value: ", inputValue); // Use the input value as needed
            }
          });
        } else {
          scanPackageSubmited(tracking);
        }
      } else if (selectedStation.finalStation) {
        if (!packageData.customer) {
          playAlertSound();
          Swal.fire({
            title: "ກະລຸນາກຳນົດຊື່ລູກຄ້າກ່ອນ!",
            icon: "warning",
            text: `${packageData.tracking} ຍັງບໍ່ມີຂໍ້ມູນລູກຄ້າເທື່ອ ກະລຸນາໄປທີ່ໜ້າ Unassigned Package`,
            showConfirmButton: true,
          });
          return;
        } else if (
          !packageData.isSelfOrder &&
          (!packageData.orderId || packageData.orderId === "")
        ) {
          playAlertSound();
          Swal.fire({
            title: "ກະລຸນາກຳນົດຂໍ້ມູນພັດສະດຸກ່ອນ!",
            icon: "warning",
            text: `${packageData.tracking} ຍັງບໍ່ມີຂໍ້ມູນເລກທີຄຳສັ່ງຊື້ແລະລາຍລະອຽດສິນຄ້າເທື່ອ ກະລຸນາໄປທີ່ໜ້າ Match Package`,
            showConfirmButton: true,
          });
          return;
        } else {
          scanPackageSubmited(tracking);
        }
      } else {
        scanPackageSubmited(tracking);
      }
    });
  };

  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (searchValue) {
        newArr = newArr.filter((element) => {
          return (
            (element.tracking &&
              element.tracking
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element.description &&
              element.description
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element.customer &&
              element.customer.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element.orderId &&
              element.orderId
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element._id.customer &&
              element._id.customer.whatsapp
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      }

      setFilteredRows(newArr);
    }
  }

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.5,
      valueGetter: (params) => params.row.customer && params.row.customer.name,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 0.7,
      hide: true,
    },
    {
      field: "tracking",
      headerName: "Tracking",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      hide: isMobile,
    },
    {
      field: "routeId",
      headerName: "Route ID",
      flex: 0.3,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
      hide: true,
    },

    {
      field: "station",
      headerName: "Station",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      hide: true,
    },
    {
      field: "isCOD",
      headerName: "COD",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isCOD ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "shippingFee",
      headerName: "ຄ່າບໍລິການ",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "finalShippingFee",
      headerName: "Final Fee",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "isSelfOrder",
      headerName: "ສັ່ງເອງ",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSelfOrder ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },

    {
      field: "isSubCustomer",
      headerName: "ລູກຄ້າຕົວແທນ",
      flex: 0.3,
      hide: isMobile || isAgentUser,
      renderCell: (cellValues) => {
        return cellValues.row.isSubCustomer ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "agentId",
      headerName: "ຕົວແທນ",
      flex: 0.3,
      hide: isMobile || isAgentUser,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.7,
      hide: isMobile,
      valueGetter: (params) =>
        moment(params.row.updatedAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.7,
      hide: true,
      valueGetter: (params) =>
        moment(params.row.createdAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
  ];

  return (
    <Box m="0 1rem">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Header title="Scan package" />
        </Grid>
        <Grid item xl={8} md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={3} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select station</InputLabel>
                <Select
                  label="Select station"
                  value={selectedStation ? selectedStation.id : ""}
                  onChange={(e) => {
                    GetStationDetailById(e.target.value).then((res) => {
                      if (res.status === 200) {
                        setSelectedStation(res.data);
                        selectAndFocus("tracking");
                        // console.log("Select station", res.data);
                      }
                    });
                  }}
                  required
                >
                  {stationList &&
                    stationList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} ({name})
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={3} md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="9px"
                    gap="3rem"
                    p="0.5rem 1rem"
                  >
                    <InputBase
                      placeholder="Tracking number"
                      id="tracking"
                      autoFocus
                      value={tracking}
                      onChange={(e) => setTracking(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setTracking(getScanText(tracking));
                          // formSubmitHandler();
                          handleFormSubmit();
                          e.preventDefault(); // Prevent the default tab behavior
                        }
                      }}
                      type="text" // Set the input type to text
                    />
                    {!isMobile ? (
                      <IconButton onClick={() => handleFormSubmit}>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setOpen(true)}>
                        <CameraAltIcon />
                      </IconButton>
                    )}
                  </FlexBetween>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Filter From"
                    inputFormat="DD/MM/yyyy"
                    value={fromDate}
                    maxDate={toDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Filter To"
                    inputFormat="DD/MM/yyyy"
                    minDate={fromDate}
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            mt={"-1rem"}
            height={"75vh"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },

              marginLeft: isMobile ? "-0.5rem" : "0",
              marginRight: isMobile ? "-0.5rem" : "0",
            }}
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={filteredRows || []}
              columns={columns}
              rowCount={filteredRows ? filteredRows.length : 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbarClientMode }}
              componentsProps={{
                toolbar: { search, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Scanner
        open={open}
        setOpen={setOpen}
        onDecode={(result) => {
          if (result) {
            console.log(result);
            setTracking(() => getScanText(result));
            handleFormSubmit();
          }
        }}
      />

      {/* Package New Dialog  */}
      <PackageNewDialog
        onClose={() => {
          setEditDialogOpen(false);
        }}
        successCount={successCount}
        setSuccessCount={setSuccessCount}
        open={editDialogOpen}
        stationObj={selectedStation}
        trackingNumber={tracking}
        disableTrackingNumber={true}
      />
    </Box>
  );
};

export default Scan;
