import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  Grid,
  Divider,
  Fade,
  useTheme,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import DeliveryAddressList from "./DeliveryAddressList";
import ForwardAddressList from "./ForwardAddressList";
import { playAlertSound, playCorrectSound, playIncorrectSound } from "utils";
import Swal from "sweetalert2";
import { QueryDistricts, QueryProvinces } from "api/general";
import { GetRouteList } from "api/routes";
import { PatchCustomer } from "api/customers";
import { GetAgentList } from "api/agent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerEditDialog = ({
  open,
  customerData,
  onClose,
  rows,
  setRows,
}) => {
  const theme = useTheme();
  const [customerId, setCustomerId] = useState(customerData?.id || "");
  const [name, setName] = useState(customerData?.name || "");
  const [whatsapp, setWhatsapp] = useState(customerData?.whatsapp || "");
  const [provinceId, setProvinceId] = useState(
    customerData?.province?.pr_id || ""
  );

  const [currentProvinceId, setCurrentProvinceId] = useState("");
  const [currentDistrictId, setCurrentDistrictId] = useState("");

  const [districtId, setDistrictId] = useState(
    customerData?.district?.dt_id || ""
  );
  const [remark, setRemark] = useState(customerData?.remark || "");
  const [validflag, setValidflag] = useState(customerData?.validflag || true);
  const [routeId, setRouteId] = useState(customerData?.default_route || "");
  const [isCOD, setIsCOD] = useState(customerData?.isCOD || false);
  const [isDeliveryRequired, setIsDeliveryRequired] = useState(
    customerData?.isDeliveryRequired || false
  );
  const [isSubCustomer, setIsSubCustomer] = useState(
    customerData?.isSubCustomer || false
  );
  const [agentId, setAgentId] = useState(customerData?.agentId || "");
  const [isForwardRequired, setIsForwardRequired] = useState(
    customerData?.isForwardRequired || false
  );
  const [isSelfOrder, setIsSelfOrder] = useState(
    customerData?.isSelfOrder || false
  );

  const [deliveryAddresses, setDeliveryAddresses] = useState(
    customerData?.deliveryAddresses || []
  );
  const [forwardAddresses, setForwardAddresses] = useState(
    customerData?.forwardAddresses || []
  );

  const [level, setLevel] = useState(1);

  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [agentList, setAgentList] = useState([]);

  useEffect(() => {
    if (customerData) {
      // Important: Check if customerData exists before setting state
      setCustomerId(customerData._id);
      setName(customerData.name);
      setWhatsapp(customerData.whatsapp);
      setProvinceId(customerData.province?.pr_id);
      setCurrentProvinceId(customerData.province?.pr_id);
      setCurrentDistrictId(customerData.district?.dt_id);
      setRemark(customerData.remark || "");
      setValidflag(customerData.validflag);
      setRouteId(customerData.default_route);
      setIsCOD(customerData.isCOD);
      setIsDeliveryRequired(customerData.isDeliveryRequired);
      setIsForwardRequired(customerData.isForwardRequired);
      setIsSubCustomer(customerData.isSubCustomer);
      setAgentId(customerData.agentId);
      setIsSelfOrder(customerData.isSelfOrder);
      setDeliveryAddresses(customerData?.deliveryAddresses || []);
      setForwardAddresses(customerData?.forwardAddresses || []);
    }
  }, [customerData]);

  useEffect(() => {
    if (provinceId) {
      setDistricts([]);
      setDistrictId("");
      QueryDistricts(provinceId).then((res) => {
        if (res && res.status === 200) {
          setDistricts(res.data);
        }
      });
    }
  }, [provinceId]);
  useEffect(() => {
    if (districts) {
      const found = districts.some(
        (district) => district.dt_id === currentDistrictId
      );
      found ? setDistrictId(currentDistrictId) : setDistrictId("");
    }
  }, [districts]);

  useEffect(() => {
    QueryProvinces().then((res) => setProvinces(res.data));
    GetRouteList().then((res) => setRouteList(res.data));
    setLevel(1);
  }, []);
  useEffect(() => {
    GetAgentList().then((res) => {
      if (res && res.status === 200) {
        setAgentList(res.data);
      }
    });
  }, [isSubCustomer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const provinceObj = provinces.find(
      (item) => item.pr_id === provinceId.toString()
    );
    const districtObj = districts.find(
      (item) => item.dt_id === districtId.toString()
    );

    if (isDeliveryRequired && deliveryAddresses.length <= 0) {
      playAlertSound();
      Swal.fire({
        title: "Location Required!",
        icon: "warning",
        text: "ກະລຸນາເພີ່ມແຜນທີ່ໃນການຈັດສົ່ງສິນຄ້າ",
      });
      return;
    }

    if (isForwardRequired && forwardAddresses.length <= 0) {
      playAlertSound();
      Swal.fire({
        title: "Forward Address Required!",
        icon: "warning",
        text: "ກະລຸນາເພີ່ມທີ່ຢູ່ໃນການຈັດສົ່ງສິນຄ້າ",
      });
      return;
    }
    PatchCustomer(
      customerId,
      name,
      whatsapp,
      provinceObj,
      districtObj,
      validflag,
      remark,
      routeId,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isSubCustomer,
      agentId,
      level,
      isSelfOrder,
      deliveryAddresses,
      forwardAddresses
    ).then((res) => {
      if (res.status === 200) {
        const updatedRows = rows.map((row) =>
          row._id === customerId
            ? {
                ...row,
                name,
                whatsapp,
                province: provinceObj,
                district: districtObj,
                validflag,
                remark,
                default_route: routeId,
                isCOD,
                isDeliveryRequired,
                isForwardRequired,
                isSubCustomer,
                agentId,
                level,
                isSelfOrder,
                deliveryAddresses,
                forwardAddresses,
              }
            : row
        );
        setRows(updatedRows);

        playCorrectSound();
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
        onClose();
      } else if (res.status === 400) {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullScreen={window.innerWidth <= 600} // Adjust breakpoint as needed
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          Edit Customer
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                  id="name"
                  aria-describedby="name-helper-text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required={true}
                  value={name}
                />
                <FormHelperText id="name-helper-text">
                  Customer name.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="whatsapp">Whatsapp</InputLabel>
                <Input
                  type="number"
                  id="whatsapp"
                  aria-describedby="whatsapp-helper-text"
                  onChange={(e) => {
                    setWhatsapp(e.target.value);
                  }}
                  required={true}
                  value={whatsapp}
                />
                <FormHelperText id="whatsapp-helper-text">
                  Whatsapp number example: 8562077790708.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth={true} margin="normal">
                <InputLabel>Province / ແຂວງ</InputLabel>
                <Select
                  id="province"
                  defaultValue=""
                  value={!provinceId ? "" : provinceId}
                  label="Province / ແຂວງ"
                  onChange={(event) => setProvinceId(event.target.value)}
                  required={true}
                >
                  {provinces &&
                    provinces.map(({ pr_id, pr_name, pr_name_en }) => {
                      return (
                        <MenuItem key={pr_id} value={pr_id}>
                          {pr_name} / {pr_name_en}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth={true} margin="normal">
                <InputLabel>District / ເມືອງ</InputLabel>
                {districts && (
                  <Select
                    id="districts"
                    // defaultValue=""
                    defaultValue=""
                    value={districtId === "" ? "" : districtId}
                    label="District / ເມືອງ"
                    onChange={(event) => setDistrictId(event.target.value)}
                    disabled={!Boolean(provinceId)}
                    required={true}
                  >
                    {districts &&
                      districts.map(({ dt_id, dt_name, dt_name_en }) => {
                        return (
                          <MenuItem key={dt_id} value={dt_id}>
                            {dt_name} / {dt_name_en}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              </FormControl>
            </Grid>

            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Route</InputLabel>
                <Select
                  id="route"
                  value={!routeId ? "" : routeId}
                  label="Route"
                  onChange={(event) => setRouteId(event.target.value)}
                  required
                >
                  {routeList &&
                    routeList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isCOD}
                    onChange={() => setIsCOD(!isCOD)}
                  />
                }
                label="COD ເກັບຄ່າບໍລິການປາຍທາງ"
              />
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isSelfOrder}
                    onChange={() => setIsSelfOrder(!isSelfOrder)}
                  />
                }
                label="ລູກຄ້າສັ່ງເຄື່ອງເອງ"
              />
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isDeliveryRequired}
                    onChange={() => setIsDeliveryRequired(!isDeliveryRequired)}
                  />
                }
                label="Home Delivery ຈັດສົ່ງສິນຄ້າຮອດເຮືອນລູກຄ້າ ພາຍໃນຂອບເຂດຕົວເມືອງ"
              />
            </Grid>

            <Fade in={isDeliveryRequired} timeout={1000} unmountOnExit>
              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                sx={{
                  "& .MuiDataGrid-root": { border: "none" },
                  "& .MuiDataGrid-cell": { borderBottom: "none" },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: theme.palette.background.alt,
                    color: theme.palette.secondary[100],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: theme.palette.primary.light,
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: theme.palette.background.alt,
                    color: theme.palette.secondary[100],
                    borderTop: "none",
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${theme.palette.secondary[200]} !important`,
                  },
                }}
              >
                <DeliveryAddressList
                  deliveryAddresses={deliveryAddresses}
                  setDeliveryAddresses={setDeliveryAddresses}
                  isDeliveryRequired={isDeliveryRequired}
                />
              </Grid>
            </Fade>

            <Grid item xl={12} md={12} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isForwardRequired}
                    onChange={() => setIsForwardRequired(!isForwardRequired)}
                  />
                }
                label="ຕ້ອງໄດ້ຝາກນຳຂົນສົ່ງພາຍໃນປະເທດຕໍ່ ເຊັ່ນ​ ຮຸ່ງອາລຸນ, ອະນຸສິດ, ມີໄຊ ..."
              />
            </Grid>
            {/* <Fade in={isForwardRequired} timeout={1000} unmountOnExit>
              <ForwardAddressList
                forwardAddresses={forwardAddresses}
                setForwardAddresses={setForwardAddresses}
                isForwardRequired={isForwardRequired}
              />
            </Fade> */}

            <Fade in={isForwardRequired} timeout={1000} unmountOnExit>
              <Grid
                item
                xl={12}
                md={12}
                xs={12}
                sx={{
                  "& .MuiDataGrid-root": { border: "none" },
                  "& .MuiDataGrid-cell": { borderBottom: "none" },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: theme.palette.background.alt,
                    color: theme.palette.secondary[100],
                    borderBottom: "none",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: theme.palette.primary.light,
                  },
                  "& .MuiDataGrid-footerContainer": {
                    backgroundColor: theme.palette.background.alt,
                    color: theme.palette.secondary[100],
                    borderTop: "none",
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${theme.palette.secondary[200]} !important`,
                  },
                }}
              >
                <ForwardAddressList
                  forwardAddresses={forwardAddresses}
                  setForwardAddresses={setForwardAddresses}
                  isForwardRequired={isForwardRequired}
                />
              </Grid>
            </Fade>
            <Grid mt={2} item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  value={remark}
                  multiline
                  maxRows={5}
                  label="Remark / ໝາຍເຫດ"
                  // placeholder="Description"
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item mt={2} mb={2} xl={12} md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item xl={6} md={6} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={isSubCustomer}
                  onChange={() => setIsSubCustomer(!isSubCustomer)}
                />
              }
              label="ລູກຄ້າຂອງຕົວແທນ"
            />
          </Grid>
          {isSubCustomer && (
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                <Select
                  id="agent"
                  value={!agentId ? "" : agentId}
                  label="ເລືອກຕົວແທນ Agent"
                  onChange={(event) => setAgentId(event.target.value)}
                  required={true}
                >
                  {agentList &&
                    agentList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={validflag}
                onChange={() => setValidflag(!validflag)}
              />
            }
            label={validflag ? "ເປີດໃຊ້ງານ" : "ປີດໃຊ້ງານ"}
          />
          <FormControl>
            <Button
              disabled={!districtId || !provinceId}
              variant="contained"
              size="large"
              type="submit"
              color="secondary"
              sx={{
                borderRadius: 1,
                padding: "10px 50px",
                m: "15px",
              }}
            >
              Save changes
            </Button>
          </FormControl>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomerEditDialog;
