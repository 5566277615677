import { api } from "./config";

export async function GetRateList(
  page = 0,
  pageSize = 20,
  sort = {}
) {
  try {
    const response = await api().get("/currency-exchange-rate/list", {
      params: {
        page,
        pageSize,
        sort: JSON.stringify(sort),
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetLatestRate() {
  try {
    const response = await api().get("/currency-exchange-rate/latest");
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function StoreRate(USD, LAK, remark) {
  try {
    const response = await api().post("/currency-exchange-rate/store", {
      USD,
      LAK,
      remark,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function PatchRate(_id, USD, LAK, remark, validflag) {
  try {
    const response = await api().patch("/currency-exchange-rate/patch", {
      _id,
      USD,
      LAK,
      remark,
      validflag,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetRateById(_id) {
  try {
    const response = await api().get("/currency-exchange-rate/" + _id);
    return response;
  } catch (error) {
    console.log(error);
  }
}
