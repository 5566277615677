// PackageNewDialog.jsx
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Switch,
  Divider,
  Button,
  Slide,
  FormHelperText,
} from "@mui/material";
import { GetAgentList } from "api/agent";
import { CloseOutlined } from "@mui/icons-material";
import {
  getScanText,
  playAlertSound,
  playBabySneezeSound,
  playCorrectSound,
  playIncorrectSound,
  selectAndFocus,
} from "utils";
import { GetCustomersList } from "api/customers";
import { GetRouteList, GetRouteListByStation } from "api/routes";
import { isMobile } from "react-device-detect";
import { StorePackage } from "api/packages";
import Swal from "sweetalert2";
import { user } from "localStorage";
import { StoreTrackingLog } from "api/trackingLogs";

// Transition component for dialog animations
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Define the props for the PackageNewDialog
const PackageNewDialog = ({
  open,
  onClose,
  stationObj,
  trackingNumber,
  disableTrackingNumber = false,
  successCount,
  setSuccessCount,
  // Data and state handlers:
}) => {
  // Package data
  const [tracking, setTracking] = useState("");
  const [description, setDescription] = useState("");
  const [orderId, setOrderId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [routeId, setRouteId] = useState("");
  const [customer, setCustomer] = useState(null);
  const [shippingFee, setShippingFee] = useState(0);
  const [status, setStatus] = useState("");
  const [station, setStation] = useState("STD");
  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);
  const [isDeliveryRequired, setIsDeliveryRequired] = useState(false);
  const [isCOD, setIsCOD] = useState(false);
  const [isSubCustomer, setIsSubCustomer] = useState(false);
  const [level, setLevel] = useState("1");
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isForwardRequired, setIsForwardRequired] = useState(false);
  const [isSelfOrder, setIsSelfOrder] = useState(false);
  const [finalShippingFee, setFinalShippingFee] = useState(0);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [forwardAddress, setForwardAddress] = useState(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [forwardAddresses, setForwardAddresses] = useState([]);

  // Front-end data
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const _user = user();
  const isAgentUser = _user.role === "agent";
  const [shippingFeeError, setShippingFeeError] = useState("");

  const handleFormSubmited = (e) => {
    e.preventDefault();
    if (isCOD && (shippingFee <= 0 || !shippingFee)) {
      setShippingFeeError("Shipping Fee must be greater than 0 for COD.");
      return; // Stop form submission
    } else {
      setShippingFeeError(""); // Clear any previous error
    }
    const customerId = customer ? customer.id : "";
    StorePackage(
      tracking,
      description,
      orderId,
      customerId,
      amount,
      quantity,
      shippingFee,
      routeId,
      status,
      remark,
      false, // paymentStatus
      station,
      false, // whatsappStatus
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isAgentUser ? true : isSubCustomer, // isSubCustomer field
      isAgentUser ? _user.agentId : agentId, // agentId field
      level,
      isSelfOrder,
      forwardAddress,
      deliveryAddress,
      finalShippingFee
    ).then((res) => {
      let data = res.result;
      if (res.status === 200) {
        if (
          stationObj.id === "VTE" &&
          data &&
          (data.routeId === "VTE-THK" || data.routeId === "VTE-DKC")
        ) {
          playBabySneezeSound();
        } else {
          playCorrectSound();
        }

        StoreTrackingLog(
          data._id,
          data.tracking,
          stationObj.name,
          stationObj.message,
          data.remark,
          _user.name
        ).then((res) => {
          // console.log(res);
          if (res.status === 200) {
            console.log(`Trackinglog has ben saved for started station.`);
            onClose();
            setSuccessCount(successCount + 1);
          } else {
            Swal.fire({
              title: "Error!",
              icon: "error",
              text: `Trackinglog saving failed, please check with developer!`,
              showConfirmButton: true,
            });
          }
        });

        Swal.fire({
          title: "Created!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
      } else if (res.status === 400) {
        playAlertSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  const clearForm = () => {
    setTracking("");
    setDescription("");
    setOrderId("");
    setAmount(0);
    setQuantity(1);
    setShippingFee(0);
    setRemark("");
    setRouteId("");
    setIsSelfOrder(false);
    setIsCOD(false);
    setIsForwardRequired(false);
    setIsDeliveryRequired(false);
    setIsSubCustomer(false);
    setAgentId("");
    setCustomer(null);
    setDeliveryAddresses([]);
    setForwardAddresses([]);
    setDeliveryAddress({});
    setForwardAddress({});
  };

  // Set defaults when a new customer is selected
  useEffect(() => {
    // Set delivery & forward addresses from customer
    if (customer) {
      setShippingFeeError(""); // Clear any previous error

      "default_route" in customer
        ? setRouteId(customer.default_route)
        : setRouteId("");
      "forwardAddresses" in customer
        ? setForwardAddresses(customer.forwardAddresses)
        : setForwardAddresses([]);
      "deliveryAddresses" in customer
        ? setDeliveryAddresses(customer.deliveryAddresses)
        : setDeliveryAddresses([]);
      "isDeliveryRequired" in customer
        ? setIsDeliveryRequired(customer.isDeliveryRequired)
        : setIsDeliveryRequired(false);
      "isForwardRequired" in customer
        ? setIsForwardRequired(customer.isForwardRequired)
        : setIsForwardRequired(false);
      "isSelfOrder" in customer
        ? setIsSelfOrder(customer.isSelfOrder)
        : setIsSelfOrder(false);
      "isSubCustomer" in customer
        ? setIsSubCustomer(customer.isSubCustomer)
        : setIsSubCustomer(false);
      "agentId" in customer ? setAgentId(customer.agentId) : setAgentId("");
      "level" in customer ? setLevel(customer.level) : setLevel(1);
      if ("isCOD" in customer) {
        setIsCOD(customer.isCOD);
        if (customer.isCOD) {
          selectAndFocus("shippingFee");
        }
      } else {
        setIsCOD(false);
      }
    }
  }, [customer]);

  useEffect(() => {
    // Find the default address
    const defaultAddress = deliveryAddresses.find(
      (address) => address.isDefault === true
    );
    console.log("🚀 ~ useEffect ~ deliveryAddresses:", deliveryAddresses);
    console.log("🚀 ~ useEffect ~ defaultAddress:", defaultAddress);
    if (defaultAddress) {
      setDeliveryAddress(defaultAddress);
    } else {
      setDeliveryAddress(null);
    }
  }, [deliveryAddresses]); // Run effect when deliveryAddresses change

  useEffect(() => {
    // Find the default address
    const defaultAddress = forwardAddresses.find(
      (address) => address.isDefault === true
    );
    if (defaultAddress) {
      setForwardAddress(defaultAddress);
    } else {
      setForwardAddress(null);
    }
  }, [forwardAddresses]); // Run effect when forwardAddresses change

  // Fetch route list
  useEffect(() => {
    if (!open) clearForm();

    if (stationObj?.id) {
      setStation(stationObj.id);
      setStatus(stationObj.parcelStatus);
      GetRouteListByStation(stationObj.id).then((res) => {
        if (res.data) setRouteList(res.data);
      });
    } else {
      GetRouteList().then((res) => {
        if (res.data) setRouteList(res.data);
      });
    }
    if (
      trackingNumber &&
      trackingNumber !== undefined &&
      trackingNumber !== ""
    ) {
      setTracking(trackingNumber);
    }
  }, [open]);

  useEffect(() => {
    if (searchCustomer && searchCustomer !== "") {
      GetCustomersList(searchCustomer).then((res) => {
        if (res.status === 200) {
          setCustomerList(res.data.customers);
        } else {
          console.error(res.message);
        }
      });
    }
  }, [searchCustomer]);

  // Fetch agent list for sub-customers
  useEffect(() => {
    if (isSubCustomer) {
      GetAgentList().then((res) => {
        if (res.data) setAgentList(res.data);
      });
    } else {
      setAgentId("");
    }
  }, [isSubCustomer]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullScreen={isMobile}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={handleFormSubmited}>
        <DialogTitle>
          New Package
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} mt={2}>
            {/* Customer Autocomplete */}
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(e, value) => {
                    setCustomer(value || null);
                  }}
                  disablePortal
                  id="combo-box-customer"
                  options={customerList}
                  value={customer}
                  isOptionEqualToValue={(option, value) =>
                    value && option.id === value.id
                  } // Custom equality check
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.whatsapp}` || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      onInput={(e) => setSearchCustomer(e.target.value)}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* Route Selection */}
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Route</InputLabel>
                <Select
                  id="route"
                  value={routeId || ""}
                  label="Route"
                  onChange={(event) => setRouteId(event.target.value)}
                  required
                >
                  {routeList &&
                    routeList.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {id} ({name})
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* COD Option */}
            <Grid item xl={2} md={2} xs={6}>
              <InputLabel>COD ຈ່າຍປາຍທາງ</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isCOD || false}
                    onChange={() => setIsCOD(!isCOD)}
                  />
                }
                label={isCOD ? "ເກັບ" : "ບໍ່ເກັບ"}
              />
            </Grid>
            {/* Self Order */}
            <Grid item xl={2} md={2} xs={6}>
              <InputLabel>ລູກຄ້າສັ່ງເຄື່ອງເອງ</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isSelfOrder || false}
                    onChange={() => setIsSelfOrder(!isSelfOrder)}
                  />
                }
                label={isSelfOrder ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
              />
            </Grid>
            <Grid container spacing={0} margin={1}>
              <Grid item xl={4} md={6} xs={12}>
                <InputLabel>
                  Home Delivery ຈັດສົ່ງສິນຄ້າຮອດເຮືອນລູກຄ້າ
                </InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!customer} // Disable if customer is null/undefined
                      color="secondary"
                      checked={isDeliveryRequired || false}
                      onChange={() =>
                        setIsDeliveryRequired(!isDeliveryRequired)
                      }
                    />
                  }
                  label={isDeliveryRequired ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                />
              </Grid>
              <Grid item xl={8} md={6} xs={12}>
                {isDeliveryRequired && (
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກທີ່ຢູ່</InputLabel>
                    <Select
                      id="deliveryAddress"
                      value={deliveryAddress?.id || ""}
                      label="ເລືອກທີ່ຢູ່"
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        const selectedAddress =
                          deliveryAddresses.find(
                            (address) => address.id === selectedId
                          ) || null;
                        setDeliveryAddress(selectedAddress);
                      }}
                      required={true}
                    >
                      {deliveryAddresses &&
                        deliveryAddresses.map((address) => (
                          <MenuItem key={address.id} value={address.id}>
                            {address.title} - {address.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={0} margin={1}>
              <Grid item xl={4} md={6} xs={12}>
                <InputLabel>
                  ຝາກນຳຂົນສົ່ງຕໍ່ ເຊັ່ນ​ ຮຸ່ງອາລຸນ, ອະນຸສິດ, ມີໄຊ ...
                </InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!customer} // Disable if customer is null/undefined
                      color="secondary"
                      checked={isForwardRequired || false}
                      onChange={() => setIsForwardRequired(!isForwardRequired)}
                    />
                  }
                  label={isForwardRequired ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                />
              </Grid>
              <Grid item xl={8} md={6} xs={12}>
                {isForwardRequired && (
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກທີ່ຢູ່</InputLabel>
                    <Select
                      id="forwardAddress"
                      value={forwardAddress?.id || ""} // Use id for comparison
                      label="ເລືອກທີ່ຢູ່"
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        const selectedAddress =
                          forwardAddresses.find(
                            (address) => address.id === selectedId
                          ) || null;
                        setForwardAddress(selectedAddress);
                      }}
                      required={true}
                    >
                      {forwardAddresses &&
                        forwardAddresses.map((address) => (
                          <MenuItem key={address.id} value={address.id}>
                            {address.logisticCompany} - {address.branch}{" "}
                            {address.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Sub-Customer Option */}
            {!isAgentUser && (
              <Grid item xl={4} md={4} xs={4}>
                <InputLabel>ລູກຄ້າຂອງຕົວແທນ</InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={isSubCustomer || false}
                      onChange={() => setIsSubCustomer(!isSubCustomer)}
                    />
                  }
                  label={isSubCustomer ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                />
              </Grid>
            )}
            {/* Additional Agent Info for Sub-Customers */}
            {!isAgentUser && isSubCustomer && (
              <>
                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                    <Select
                      id="agent"
                      value={agentId || ""}
                      label="ເລືອກຕົວແທນ Agent"
                      onChange={(event) => setAgentId(event.target.value)}
                      required
                    >
                      {agentList &&
                        agentList.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {id} - {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Final Shipping Fee</InputLabel>
                    <OutlinedInput
                      value={finalShippingFee}
                      type="number"
                      label="Final Shipping Fee"
                      id="finalShippingFee"
                      onChange={(e) => setFinalShippingFee(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xl={12} md={12} xs={12} mb={2}>
              <Divider />
            </Grid>
            {/* Tracking Number */}
            <Grid item xl={3} md={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tracking Number</InputLabel>
                <OutlinedInput
                  label="Tracking Number"
                  id="tracking"
                  value={tracking}
                  disabled={disableTrackingNumber}
                  required
                  onChange={(e) => setTracking(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setTracking(getScanText(tracking));
                      // document.getElementById("shippingFee").select();
                      // document.getElementById("shippingFee").focus();
                      selectAndFocus("shippingFee");
                      e.preventDefault();
                    }
                  }}
                />
              </FormControl>
            </Grid>
            {/* Order ID */}
            <Grid item xl={3} md={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Order ID</InputLabel>
                <OutlinedInput
                  type="text"
                  label="Order ID"
                  value={orderId}
                  id="orderId"
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* Amount */}
            <Grid item xl={3} md={3} xs={6}>
              <FormControl fullWidth>
                <InputLabel>Amount</InputLabel>
                <OutlinedInput
                  type="number"
                  label="Amount"
                  value={amount}
                  id="amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* Shipping Fee / Final Shipping Fee */}
            {isAgentUser ? (
              <Grid item xl={3} md={3} xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Final Shipping Fee</InputLabel>
                  <OutlinedInput
                    value={finalShippingFee}
                    type="number"
                    label="Final Shipping Fee"
                    id="finalShippingFee"
                    onChange={(e) => setFinalShippingFee(e.target.value)}
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item xl={3} md={3} xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Shipping Fee</InputLabel>
                  <OutlinedInput
                    value={shippingFee}
                    type="number"
                    label="Shipping Fee"
                    id="shippingFee"
                    onChange={(e) => setShippingFee(e.target.value)}
                    disabled={isAgentUser}
                  />
                </FormControl>
                {shippingFeeError && (
                  <FormHelperText sx={{ color: "red" }}>
                    {shippingFeeError}
                  </FormHelperText>
                )}
              </Grid>
            )}
            {/* Description */}
            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={5}
                  label="Description"
                  value={description}
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* Remark */}
            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  maxRows={5}
                  label="Remark"
                  value={remark}
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                />
              </FormControl>
            </Grid>

            {/* Quantity */}
            {/* <Grid item xl={4} md={4} xs={4}>
              <FormControl fullWidth>
                <InputLabel>Quantity</InputLabel>
                <OutlinedInput
                  type="number"
                  value={quantity}
                  label="Quantity"
                  id="quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={3}
            mb={2}
            mr={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid item xl={3} md={3} xs={4}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  color="secondary"
                >
                  Save
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PackageNewDialog;
