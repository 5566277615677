import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useTheme } from "@emotion/react";
import { GetStationDetailById, GetFinalStationList } from "api/stations";
import {
  GetSumPackagesForHomeDeliveryPackages,
  PatchPackageDelivery,
} from "api/packages";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridCustomToolbarClientMode } from "components/DataGridCustomToolbar";
import {
  copyTextToClipboard,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { defaultStation, user } from "localStorage";
import { isMobile } from "react-device-detect";
import { SendMessage } from "api/whatsapp";
import Swal from "sweetalert2";
import { StoreTrackingLog } from "api/trackingLogs";

const HomeDelivery = () => {
  const theme = useTheme();
  const [selectedStation, setSelectedStation] = useState(null);
  const [rows, setRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stationList, setStationList] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);
  const [endDescription, setEndDesciption] = useState("");

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");

  const [isSending, setIsSending] = useState(false);
  const _user = user();
  const _defaultStation = defaultStation();

  function RefreshPackagesList(stationId) {
    setRows([]);
    setIsLoading(true);
    GetSumPackagesForHomeDeliveryPackages(stationId).then((res) => {
      if (res.status === 200) {
        setRows(res.data);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (selectedStation) {
      RefreshPackagesList(selectedStation.id);
    }
  }, [selectedStation]);

  useEffect(() => {
    GetFinalStationList().then((res) => {
      if (res && res.status === 200) {
        setStationList(res.data);
      }
      setSelectedStation(_defaultStation);
    });
    GetStationDetailById("END").then((res) => {
      if (res && res.status === 200) {
        setEndDesciption(res.data.message);
      }
    });
  }, []);

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (searchValue) {
        newArr = newArr.filter((element) => {
          return (
            (element._id.customer &&
              element._id.customer.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element._id.customer &&
              element._id.customer.whatsapp
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      }
      setFilteredRows(newArr);
    }
  }

  function deleteRow(dataArr, rowId) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (rowId) {
        newArr = newArr.filter((element) => {
          return element._id.customer.id !== rowId;
        });
      }
      setFilteredRows(newArr);
    }
  }

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => params.row._id.customer._id,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.7,
      valueGetter: (params) => params.row._id.customer.name,
    },
    {
      field: "whatsapp",
      headerName: "Whatsapp",
      flex: 1,
      valueGetter: (params) => params.row._id.customer.whatsapp,
    },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      flex: 2,
      // valueGetter: (params) => params.row.deliveryAddress,
      renderCell: (cellValues) => {
        const address = cellValues.row.deliveryAddress;
        const googleMap = cellValues.row.googleMap;
        return (
          <a
            href={`${googleMap}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: theme.palette.secondary[200],
            }}
          >
            {address}
          </a>
        );
      },
    },

    {
      field: "smsMessage",
      headerName: "Message",
      flex: 3,
      hide: isMobile,
      valueGetter: (params) => params.row.smsMessage,
    },
    {
      field: "totalPackages",
      headerName: "item(s).",
      flex: 0.5,
      valueGetter: (params) => params.row.totalPackages,
    },
    {
      field: "totalShippingFee",
      headerName: "Fee",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "copy",
      headerName: "Copy",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              copyTextToClipboard(cellValues.row.smsMessage);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ContentCopyIcon />
          </IconButton>
        );
      },
    },
    {
      field: "send",
      headerName: "Send",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            disabled={isSending}
            onClick={() => {
              sendWhatsappMessage(cellValues.row.smsMessage, cellValues.row);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <SendIcon />
          </IconButton>
        );
      },
    },
  ];

  const sendWhatsappMessage = (message, dataObj) => {
    setIsSending(true);
    let phone = dataObj._id.customer.whatsapp;

    SendMessage(phone, message).then((res) => {
      console.log("🚀 ~ SendMessage ~ res:", res);
      if (res && res.status === 200) {
        // console.log(res.data.message);
        const packageDetail = dataObj.packageDetail;
        // console.log(packageDetail);
        const station = "END";
        const status = "Delivered";
        const paymentSatatus = true;
        const whatsappSatatus = true;
        const deliveryMethod = "Home-delivery";

        for (let index = 0; index < packageDetail.length; index++) {
          // console.log(`index: ${index} data.lengthL: ${data.packageDetail.length - 1}` )

          PatchPackageDelivery(
            packageDetail[index]._id,
            station,
            status,
            paymentSatatus,
            whatsappSatatus,
            deliveryMethod
          ).then((res) => {
            if (res.status === 200 && index === packageDetail.length - 1) {
              playCorrectSound();
              StoreTrackingLog(
                packageDetail[index]._id,
                packageDetail[index].tracking,
                "END",
                endDescription,
                _user.name,
                true
              ).then((res)=>{
                if(res && res.status === 200){
                  console.log(`tracking logs for ${packageDetail[index].tracking} saved successfully.`)
                } else {
                  console.log(`[ERROR] - tracking logs for ${packageDetail[index].tracking}  saving failed.`)
                }
              });
              deleteRow(filteredRows, dataObj._id.customer.id);
              Swal.fire({
                text: "Whatsapp sending success",
                icon: "success",
                position: "top-right",
                timer: 1200,
                toast: true,
                showConfirmButton: false,
              });
              console.log(`Packages has been updated`);
            }
            if (res.status !== 200) {
              playAlertSound();
              console.log(res.message);
            }
          });
        }
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res.data.message,
          timer: 3000,
        });
        setIsSending(false);
      }
      setIsSending(false);
    });
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1}>
        <Grid marginY={1} item xl={12} md={12} xs={12}>
          <Typography variant="h4" color="secondary">
            ຈັດສົ່ງສິນຄ້າໃຫ້ລູກຄ້າຢູ່ບ້ານ Home Delivery
          </Typography>
        </Grid>
        <Grid mt={"-0.5rem"} item xl={12} md={12} xs={12}>
          {/* Filtering section */}
          <Grid container spacing={2}>
            <Grid item xl={3} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select station</InputLabel>
                <Select
                  label="Select station"
                  value={selectedStation ? selectedStation.id : ""}
                  onChange={(e) => {
                    GetStationDetailById(e.target.value).then((res) => {
                      if (res.status === 200) {
                        setSelectedStation(res.data);
                      }
                    });
                  }}
                  required
                >
                  {stationList &&
                    stationList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid mt={"-0.5rem"} item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            height="73vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              loading={isLoading}
              getRowId={(row) =>
                row._id.customer._id + row._id.deliveryAddressId
              }
              rows={filteredRows || []}
              columns={columns}
              rowCount={filteredRows ? filteredRows.length : 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbarClientMode }}
              componentsProps={{
                toolbar: { search, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeDelivery;
