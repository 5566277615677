import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

// import { createTheme } from "@mui/material/styles";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
// Admin Scenes
import Layout from "scenes/layout";
import Customers from "scenes/customers";
import Packages from "scenes/packages";
import Users from "scenes/users";
import Scan from "scenes/scan";
import WhatsApp from "scenes/whatsapp";
import ShippingFee from "scenes/shippingfee";
import Tracking from "scenes/tracking";
// import Delivered from "scenes/delivered";
// Customer Scenes
import CustomerMain from "scenes/customerPortal";
//Public Scenes
import UserLogin from "scenes/public/userLogin";
import CustomerLogin from "scenes/public/login";
import PublicTracking from "scenes/public/tracking";

import Roles from "scenes/role";
import { logout } from "utils";
import AuthVerify from "utils/AuthVerify";
import Delivered from "scenes/delivered";
import BulkMove from "scenes/bulkmove";
import UnassginedPackage from "scenes/unassignedPackages";
import MatchPackage from "scenes/matchPackage";
import Agent from "scenes/agent";
import { user } from "localStorage";
import Settings from "scenes/settings";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  // console.log("Frontend is runing");
  const _user = user();
  const roles = ["agent", "user", "admin"];

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* <Route path="/" element={<Navigate to="/login" replace />} />             */}
          {localStorage.getItem("token") && roles.includes(_user.role) ? (
            <>
              <Routes>
                <Route element={<Layout />}>
                  {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                  <Route path="*" element={<Scan />} />
                  <Route path="/packages" element={<Packages />} />
                  <Route path="/customers" element={<Customers />} />

                  <Route path="/scan" element={<Scan />} />
                  <Route path="/whatsapp" element={<WhatsApp />} />
                  <Route path="/shippingfee" element={<ShippingFee />} />
                  <Route path="/tracking" element={<Tracking />} />
                  <Route path="/delivery" element={<Delivered />} />
                  <Route path="/customer/:id" element={<CustomerMain />} />
                  {_user.role !== "agent" && (
                    <>
                      <Route path="/bulkmove" element={<BulkMove />} />
                      <Route path="/agent" element={<Agent />} />
                    </>
                  )}

                  {_user.role === "admin" && (
                    <>
                      <Route path="/roles" element={<Roles />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/settings" element={<Settings />} />
                    </>
                  )}
                  <Route
                    path="/unassignedpackage"
                    element={<UnassginedPackage />}
                  />
                  <Route path="/matchpackage" element={<MatchPackage />} />
                </Route>
              </Routes>
              <AuthVerify logOut={logOut} />
            </>
          ) : (
            <Routes>
              <Route path="/" element={<PublicTracking />} />
              <Route path="/admin/login" element={<UserLogin/>} />
              <Route path="/login" element={<CustomerLogin/>} />
              <Route path="*" element={<PublicTracking />} />
            </Routes>
          )}
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
