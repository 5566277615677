// WhatsAppQR.jsx (Frontend)
import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react"; // Import useRef
import io from "socket.io-client";
import whatsappIcon from "../assets/WhatsApp.svg-200x200.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const WhatsAppQR = () => {
  const [qrCode, setQrCode] = useState(null);
  const [status, setStatus] = useState("Connecting to server...");
  const [loading, setLoading] = useState(true);
  const [myNumber, setMyNumber] = useState("");
  const [isConnected, setIsConnected] = useState(false);

  const socketRef = useRef(null); // Create a ref for the socket

  useEffect(() => {
    console.log("WhatsAppQR useEffect running"); // Add this log
    let newSocket;

    // Determine Socket.IO URL based on environment
    const socketUrl =
      process.env.NODE_ENV === "production"
        ? undefined
        : process.env.REACT_APP_SOCKET_IO;

    newSocket = io(socketUrl, {
      transports: ["websocket", "polling"],
      reconnection: false, // Disable reconnection for now
    });

    socketRef.current = newSocket; // Store the socket in the ref

    newSocket.on("connect_error", (error) => {
      console.error("Connection error:", error);
      setStatus(`Connection error: ${error.message}`);
      setLoading(false);
      setIsConnected(false);
    });

    newSocket.on("whatsapp-qr", (qr) => {
      console.log("QR Code received:", qr.slice(0, 100)); // Log a snippet
      setQrCode("")
      setQrCode(qr); // This should include the data:image/png;base64, prefix
      setStatus("Scan the QR code to login");
      setLoading(false);
    });

    newSocket.on("whatsapp-ready", (ready) => {
      if (ready) {
        console.log("WhatsApp is ready!");
        setStatus("WhatsApp is connected!");
        setIsConnected(true);
        setQrCode(null);
        setLoading(false);
      }
    });

    newSocket.on("my-number", (number) => {
      setMyNumber(number);
    });

    newSocket.on("whatsapp-status", (statusData) => {
      // statusData is the object
      console.log("WhatsApp status received:", statusData); // Log the entire object

      if (statusData && statusData.status === "ready") {
        // Check statusData.status
        console.log("WhatsApp is ready!!!");
        setStatus("WhatsApp is connected!");
        setIsConnected(true);
        setQrCode(null);
        setLoading(false);
      } else if (statusData && statusData.status === "connecting") {
        // Check statusData.status
        console.log("WhatsApp is connecting!");
        setStatus("WhatsApp is connecting...");
        setQrCode(null);
      } else if (statusData && statusData.status === "not_initialized") {
        // Check statusData.status
        console.log("WhatsApp is not initialized yet!");
        setStatus("WhatsApp is not initialized yet!");
      } else if (statusData && statusData.status === "disconnected") {
        // Handle disconnected
        setStatus(
          "WhatsApp disconnected: " + (statusData.reason || "Unknown reason")
        );
        setQrCode(null);
        setLoading(false);
      }
    });

    newSocket.on("whatsapp-authenticated", (authenticated) => {
      if (authenticated) {
        console.log("WhatsApp is authenticated!");
        setStatus("WhatsApp is authenticated!");
        setQrCode(null);
        setLoading(false);
      }
    });

    newSocket.on("disconnected", () => {
      setStatus("WhatsApp disconnected.");
      setQrCode(null);
      setLoading(false);
      setIsConnected(false);
    });

    newSocket.on("auth_failure", (msg) => {
      setStatus(`Authentication failed: ${msg}`);
      setLoading(false);
      setIsConnected(false);
    });

    newSocket.on("whatsapp-initialization-error", (errorMessage) => {
      console.error("WhatsApp initialization error:", errorMessage);
      setStatus(`WhatsApp Initialization Error: ${errorMessage}`);
      setLoading(false);
      setIsConnected(false);
    });

    return () => {
      if (socketRef.current) {
        // Use socketRef.current in cleanup
        socketRef.current.off("connect_error");
        socketRef.current.off("server-ready");
        socketRef.current.off("qr");
        socketRef.current.off("ready");
        socketRef.current.off("disconnected");
        socketRef.current.off("auth_failure");
        socketRef.current.off("whatsapp-initialization-error");
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(()=>{
    console.log(qrCode)
  },[qrCode])

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {isConnected ? (
        <>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12}>
              <Box
                component="img" // Use Box component with "img" for image
                src={whatsappIcon}
                alt="whatsapp icon"
                sx={{
                  // Add styling with sx prop
                  maxWidth: "100%", // Make image responsive
                  height: "auto", // Maintain aspect ratio
                  display: "block", // Prevent default inline behavior
                  margin: "0 auto", // Center the image
                }}
              />
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <Typography variant="h4" mb={1}>
                <CheckBoxIcon
                  sx={{
                    color: "#2AD582",
                    fontSize: "2rem",
                    marginBottom: "-0.5rem",
                  }}
                />{" "}
                {status}
              </Typography>
              <Typography variant="h5">
                with phone number: {myNumber}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12}>
              <Box
                component="img" // Use Box component with "img" for image
                src={whatsappIcon}
                alt="whatsapp icon"
                sx={{
                  // Add styling with sx prop
                  maxWidth: "100%", // Make image responsive
                  height: "auto", // Maintain aspect ratio
                  display: "block", // Prevent default inline behavior
                  margin: "0 auto", // Center the image
                }}
              />
            </Grid>
            <Grid item xl={12} md={12} xs={12}>
              <Typography variant="h4">{status}</Typography>
              {loading && <Typography variant="h4">Loading...</Typography>}
              {qrCode && (
                <>
                  <Box
                    component="img" // Use Box component with "img" for image
                    src={qrCode}
                    alt={status}
                    sx={{
                      // Add styling with sx prop
                      maxWidth: "100%", // Make image responsive
                      height: "auto", // Maintain aspect ratio
                      display: "block", // Prevent default inline behavior
                      margin: "0 auto", // Center the image
                    }}
                  />
                </>
              )}
              {status.startsWith("Connection error") && (
                <p style={{ color: "red" }}>{status}</p>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default WhatsAppQR;
