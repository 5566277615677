import React from "react";
import { Box, Grid } from "@mui/material";
import WhatsAppQR from "components/WhatsAppQR";

const WhatsAppLogin = () => {
  return (
    <Box m="0 1rem">
      <Grid container>
        <Grid item xl={4} md={6} xs={12}>
          <WhatsAppQR />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatsAppLogin;
