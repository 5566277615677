import React from "react";
import {
  Box,
  // Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  // SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  // ShoppingCartOutlined,
  Groups2Outlined,
  // ReceiptLongOutlined,
  // PublicOutlined,
  // PointOfSaleOutlined,
  // TodayOutlined,
  // CalendarMonthOutlined,
  // AdminPanelSettingsOutlined,
  // TrendingUpOutlined,
  // PieChartOutlined,
} from "@mui/icons-material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import WidgetsIcon from "@mui/icons-material/Widgets";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NavigationIcon from "@mui/icons-material/Navigation";
import RoomIcon from "@mui/icons-material/Room";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SecurityIcon from "@mui/icons-material/Security";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import { user } from "localStorage";
import { isNullOrUndefined } from "html5-qrcode/esm/core";
// import profileImage from "assets/profile.jpeg";

// const initialItem = [
//   {
//     text: "Scan Package",
//     icon: null,
//   }
// ];

const navItems = [
  {
    text: "General Management",
    icon: null,
  },
  {
    text: "Tracking",
    icon: <TravelExploreIcon />,
  },
  {
    text: "Scan",
    icon: <QrCodeScannerIcon />,
  },
  {
    text: "Unassigned Package",
    icon: <HelpCenterIcon />,
  },
  {
    text: "Match Package",
    icon: <LibraryAddCheckIcon />,
  },
  {
    text: "Bulk Move",
    icon: <MoveUpIcon />,
  },
  // {
  //   text: "Shipping Fee",
  //   icon: <MonetizationOnIcon />,
  // },
  {
    text: "Packages",
    icon: <WidgetsIcon />,
  },
  {
    text: "Customers",
    icon: <Groups2Outlined />,
  },
  {
    text: "Notification",
    icon: null,
  },
  {
    text: "Agent",
    icon: <GroupsIcon />,
  },
  {
    text: "WhatsApp",
    icon: <WhatsAppIcon />,
  },
  {
    text: "Delivery",
    icon: <DoneAllIcon />,
  },
  // {
  //   text: "Roles",
  //   icon: <SecurityIcon />,
  // },
  // {
  //   text: "Routes",
  //   icon: <NavigationIcon />,
  // },
  // {
  //   text: "Stations",
  //   icon: <RoomIcon />,
  // },
];
const _user = user();
if (_user && _user.role === "admin") {
  navItems.push(
    {
      text: "Administration",
      icon: null,
    },
    {
      text: "Settings",
      icon: <SettingsSharpIcon />,
    },
    {
      text: "Users",
      icon: <HomeOutlined />,
    },
    {
      text: "Roles",
      icon: <SecurityIcon />,
    }
    // ,{
    //   text: "Routes",
    //   icon: <NavigationIcon />,
    // },
    // {
    //   text: "Stations",
    //   icon: <RoomIcon />,
    // }
  );
}

const fileteredNavItems =
  !isNullOrUndefined(_user) && _user.role === "agent"
    ? navItems.filter((item) => item.text !== "Bulk Move" && item.text !== "Agent")
    : navItems;

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          transitionDuration={{ enter: 500, exit: 500 }}
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box>
            <Box width="100%">
              <Box m="1rem 2rem 0rem 3rem">
                <FlexBetween color={theme.palette.secondary.main}>
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Typography variant="h4" fontWeight="bold">
                      Winner Express
                    </Typography>
                  </Box>
                  {!isNonMobile && (
                    <IconButton
                      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  )}
                </FlexBetween>
              </Box>
              <List>
                {fileteredNavItems.map(({ text, icon }) => {
                  if (!icon) {
                    return (
                      <Typography
                        key={text}
                        sx={{ m: "2.25rem 0 1rem 3rem", fontSize: "14px" }}
                      >
                        {text}
                      </Typography>
                    );
                  }
                  const lcText = text.replace(/\s/g, "").toLowerCase();

                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          if (!isNonMobile) setIsSidebarOpen(false);
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? "white"
                              : theme.palette.secondary[100],
                          "&:hover": {
                            backgroundColor: theme.palette.secondary[500],
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "0.5rem",
                            color:
                              active === lcText
                                ? "white"
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{
                            ml: "-0.5rem",
                          }}
                        />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
