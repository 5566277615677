import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Authen } from "api/public.js";
import { Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { setUserData, setDefaultStation } from "slices/userSlice";
import { useNavigate } from "react-router-dom";
import winnerLogo from "../../../assets/winner-logo.png";
import axios from "axios";
import whatsappIcon from "../../../assets/WhatsApp.svg-200x200.png";
import { isMobile } from "react-device-detect";
import backgroundImage from "../../../assets/background-from-ChatGPT.png";

const baseUrl = process.env.REACT_APP_BASE_URL + "/public";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Winner Express
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  // const [isAuthen, setIsAuthen] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const dispatch = useDispatch();

  const setUserState = (userData, defaultStation) => {
    dispatch(setUserData(userData));
    dispatch(setDefaultStation(defaultStation));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    await axios
      .post(baseUrl + "/login", {
        email: data.get("email"),
        password: data.get("password"),
      })
      .then((res) => {
        if (res && res.status === 200) {
          setLoginFailed(false);
          setUserState(res.data.user, res.data.defaultStation);
          console.log(res.data);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem(
            "defaultStation",
            JSON.stringify(res.data.defaultStation)
          );
          window.location.replace("/scan");
        }
      })
      .catch(function (error) {
        console.log(error); // 401
        // console.log(error); //Please Authenticate or whatever returned from server
        if (error.response.status === 401) {
          console.log("login failed");
          setLoginFailed(true);
        }
      });
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (typeof token === "string" && token.length > 0) {
      Authen(token).then((res) => {
        if (res.status === 200) {
          navigate("/tracking");
        } else {
          localStorage.removeItem("token");
          window.location = "/login";
        }
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        direction="column"
        alignItems="center"
        container
        component="main"
        sx={{
          height: "100vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Grid
          borderRadius={5}
          mt={isMobile ? 0 : 10}
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square         
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img" // Use Box component with "img" for image
              src={winnerLogo}
              alt="Winner Logo"
              sx={{
                // Add styling with sx prop
                maxWidth: "300px", // Make image responsive
                height: "auto", // Maintain aspect ratio
                display: "block", // Prevent default inline behavior
                margin: "0 auto", // Center the image
              }}
            />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="whatsapp"
                label="Whatsapp number"
                name="whatsapp"
                autoComplete="whatsapp"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {loginFailed && (
                <Alert severity="error">
                  Whatsapp number or password is invalid!
                </Alert>
              )}
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
               */}
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
              >
                Sign In
              </Button>
              <Typography align="center"> or </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Link
                  href="" // Add your desired link URL here
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none", // Remove default underline
                    color: "inherit", // Inherit text color from parent
                  }}
                >
                  <Box
                    component="img"
                    src={whatsappIcon}
                    alt="WhatsApp Icon"
                    sx={{
                      width: 60,
                      height: 60,
                    }}
                  />
                  <Typography>Login via Whatsapp</Typography>
                </Link>
              </Box>
              <Copyright sx={{ mt: 10 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
