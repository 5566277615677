import correctSound from "../sound/correct-answer-notification.wav";
import incorrectSound from "../sound/incorrect-sound.wav";
import alertSound from "../sound/correct-positive-answer.wav";
import babySneezeSound from "../sound/mixkit-little-baby-sneeze-2214.wav";
import readyBeepSound from "../sound/mixkit-appliance-ready-beep-1076.wav";
import policeWhistle from "../sound/mixkit-police-short-whistle-615.wav";
import yahooSound from "../sound/sound-effect-YAHOO.wav";
import { user } from "localStorage";
import { GetRouteList } from "api/routes";

export function copyTextToClipboard(
  text = "Please provide text to copy to clipboard!"
) {
  navigator.clipboard.writeText(text);
  console.log(`Text copied!`);
}

export function selectAndFocus(elementId) {
  document.getElementById(elementId).select();
  document.getElementById(elementId).focus();
}

export function getScanText(text = "Pleasae provide text as string type") {
  let newText = text.toUpperCase().trim();
  if (newText.indexOf(" ") > 0) {
    newText = newText.slice(0, newText.indexOf(" "));
  }
  // console.log(newText)
  return newText;
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function logout() {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const defaultStation = localStorage.getItem("defaultStation");

  if (token) {
    localStorage.removeItem("token");
  }
  if (defaultStation) {
    localStorage.removeItem("defaultStation");
  }
  if (user) {
    localStorage.removeItem("user");
  }
  window.location = "/admin/login";
}

export function playCorrectSound() {
  var audio = new Audio(correctSound);
  audio.play();
}

export function playIncorrectSound() {
  var audio = new Audio(incorrectSound);
  audio.play();
}

export function playAlertSound() {
  var audio = new Audio(alertSound);
  audio.play();
}

export function playBabySneezeSound() {
  var audio = new Audio(babySneezeSound);
  audio.play();
}

export function playReadyBeebSound() {
  var audio = new Audio(readyBeepSound);
  audio.play();
}
export function playPoliceWhistleSound() {
  var audio = new Audio(policeWhistle);
  audio.play();
}
export function playYahooSound() {
  var audio = new Audio(yahooSound);
  audio.play();
}

export async function getValidRoutesByUser() {
  const _user = user();
  const stationIds = _user.stations.map((station) => station.id);
  const routes = await GetRouteList();
  console.log("🚀 ~ getValidRoutesByUser ~ routes:", routes);

  var filteredRoutes = [];
  if (routes && routes.data) {
    filteredRoutes = routes.data.filter((route) =>
      route.navigator.some(
        (nav) => stationIds.includes(nav.station) && nav.isFinalStation
      )
    );
  }

  console.log("🚀 ~ getValidRoutesByUser ~ filteredRoutes:", filteredRoutes);

  return filteredRoutes;
}
