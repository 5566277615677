// DeliveryAddressList.jsx
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, useTheme, Button, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import DeliveryAddressDialog from "components/DeliveryAddressDialog"; // Import the dialog

const DeliveryAddressList = ({
  deliveryAddresses,
  setDeliveryAddresses,
  isDeliveryRequired,
}) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const handleSaveLocation = (newAddress) => {
    if (deliveryAddresses.length >= 5) {
      alert("You can only add up to 5 delivery addresses.");
      return;
    }
    const isMapUnique = !deliveryAddresses.some(
      (address) => address.map === newAddress.map
    );
    if (!isMapUnique) {
      alert("The map URL must be unique. This map URL already exists.");
      return;
    }
    setDeliveryAddresses((prevAddresses) => {
      const updatedAddresses = prevAddresses.map((address) => ({
        ...address,
        isDefault: false,
      }));
      return [newAddress, ...updatedAddresses];
    });
    setDialogOpen(false); //close dialog after save
  };

  const handleRemove = (id) => {
    setDeliveryAddresses((prevAddresses) => {
      const updatedAddresses = prevAddresses.filter(
        (address) => address.id !== id
      );
      if (
        updatedAddresses.length > 0 &&
        prevAddresses.find((address) => address.id === id).isDefault
      ) {
        updatedAddresses[0].isDefault = true;
      }
      return updatedAddresses;
    });
  };

  const buttonDisableEnableHandler = (value) => {
    const targetId = value.id;
    if (deliveryAddresses.length === 1 && deliveryAddresses[0].isDefault) {
      return;
    }
    setDeliveryAddresses((prevAddresses) => {
      let newAddresses = prevAddresses.map((address) => {
        if (address.id === targetId) {
          return { ...address, isDefault: !value.isDefault };
        }
        return address;
      });

      const targetAddress = newAddresses.find((addr) => addr.id === targetId);
      if (targetAddress && targetAddress.isDefault) {
        newAddresses = newAddresses.map((address) => {
          if (address.id !== targetId && address.isDefault) {
            return { ...address, isDefault: false };
          }
          return address;
        });
      }

      return newAddresses;
    });
  };

  const deliveryColumns = [
    { field: "id", headerName: "ID", flex: 1, hide: true },
    {
      field: "no",
      headerName: "No.",
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
      flex: 0.5,
    },
    { field: "map", headerName: "Map", flex: 2 },
    { field: "title", headerName: "Title", flex: 2 },
    { field: "description", headerName: "Description", flex: 3 },
    {
      field: "isDefault",
      headerName: "Default",
      flex: 1,
      renderCell: (cellValues) => {
        return cellValues.row.isDefault ? (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={() => buttonDisableEnableHandler(cellValues.row)}
          >
            <ToggleOnIcon color="secondary" />
          </IconButton>
        ) : (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={() => buttonDisableEnableHandler(cellValues.row)}
          >
            <ToggleOffIcon />
          </IconButton>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleRemove(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {isDeliveryRequired && (
        <Grid container spacing={1}>
          <Grid item xl={12} md={12} xs={12} container>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ px: 5, mb: 1}}
              onClick={handleOpenDialog}
            >
              New location
            </Button>
            <DeliveryAddressDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              onSave={handleSaveLocation}
            />
          </Grid>
        </Grid>
      )}
      <DataGrid
        autoHeight
        columns={deliveryColumns}
        getRowId={(row) => row.id}
        rows={deliveryAddresses || []}
        rowCount={deliveryAddresses ? deliveryAddresses.length : 0}
        hideFooter={true}
      />
    </>
  );
};

export default DeliveryAddressList;
