import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { GetStationDetailById, GetFinalStationList } from "api/stations";
import { defaultStation, user } from "localStorage";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { GetCustomersList } from "api/customers";
import {
  GetReadyToPickupPackages,
  GetSumReadyToPickupPackages,
  PatchPackageDelivery,
} from "api/packages";
import Swal from "sweetalert2";
import { grey } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import FlexBetween from "components/FlexBetween";
import {
  copyTextToClipboard,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import { Edit } from "@mui/icons-material";
import { StoreTrackingLog } from "api/trackingLogs";
import { GetLatestRate } from "api/currencyExchangeRate";
import PackageEditDialog from "components/PackageEditDialog";

function NewDeliver() {
  const theme = useTheme();
  const [stationList, setStationList] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [nextStation, setNextStation] = useState("");
  const [rows, setRows] = useState(null);

  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // Package data
  const [packageData, setPackageData] = useState(null);

  // Data for front-end
  const [customer, setCustomer] = useState({});
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [collapseList, setCollapseList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalShippingFee, setTotalShippingFee] = useState(0);
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState(null);
  const [feeUSD, setFeeUSD] = useState(0);
  const [feeLAK, setFeeLAK] = useState(0);

  const _user = user();
  const _defaultStation = defaultStation();
  const isAgentUser = _user.role === "agent";

  useEffect(() => {
    GetFinalStationList().then((res) => {
      if (res && res.status === 200) {
        let arr = res.data;
        let validStation = [];
        if (Array.isArray(arr)) {
          arr.forEach((finalStation) => {
            // console.log("finalStation", finalStation);
            _user.stations.forEach((userStation) => {
              // console.log("userStation", userStation);
              if (finalStation.id === userStation.id)
                validStation.push(finalStation);
            });
          });
        }
        if (validStation) setStationList(validStation);
      }

      GetStationDetailById(_defaultStation.id).then((res) => {
        if (res && res.status === 200) {
          setSelectedStation(res.data);
        }
      });

      GetStationDetailById("END").then((res) => {
        if (res && res.status === 200) {
          setNextStation(res.data);
        }
      });
    });
    GetLatestRate().then((res) => {
      if (res.status === 200) {
        setCurrencyExchangeRate(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (searchCustomer) {
      GetCustomersList(searchCustomer).then((res) => {
        if (res.status === 200) {
          setCustomerList(res.data.customers);
        } else {
          console.log(res.message);
        }
      });
    }
  }, [searchCustomer]);

  useEffect(() => {
    if (customer && selectedStation) {
      // console.log(customer);
      GetReadyToPickupPackages(customer.id, selectedStation.id).then((res) => {
        if (res && res.status === 200) {
          let packages = res.data.packages;
          //define obj.sendStatus
          packages.forEach((item, index) => {
            packages[index].sendStatus = true;
          });

          setRows(packages);
          setCollapseList(() => new Array(packages.length).fill(false));
        } else {
          console.log(res.message);
          Swal.fire({
            title: "API call error",
            text: res.message,
            icon: "error",
          });
        }
      });
    }
  }, [customer, selectedStation]);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleExpandClick = (index) => {
    console.log("expand clicked!");
    const newList = collapseList.map((item, i) => {
      return i === index ? !item : item;
    });
    setCollapseList(newList);
    // setExpanded(!expanded);
  };

  const handleSendStatus = (index) => {
    // Clone state => rows
    const newList = [...rows];
    newList.forEach((packageItem, i) => {
      // if packageItem index === index ? set sendStatus to opposite value else return current value
      return (newList[i].sendStatus =
        i === index ? !packageItem.sendStatus : packageItem.sendStatus);
    });
    setRows(newList);
  };

  const handleSendButtonClicked = () => {
    console.log("send button clicked!");
    let sendItems = rows.filter((item) => item.sendStatus === true);
    let trackingNumbers = sendItems.map((item) => item.tracking);
    GetSumReadyToPickupPackages(
      customer.id,
      trackingNumbers,
      selectedStation.id,
      true
    ).then((res) => {
      console.log(res);
      if (res && res.status === 200) {
        // copyTextToClipboard(res.data.smsMessage);
        setRows(() => rows.filter((item) => item.sendStatus === false));
        playCorrectSound();
        Swal.fire({
          text: "Send message success",
          icon: "success",
          position: "top-right",
          timer: 1200,
          toast: true,
          showConfirmButton: false,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          text: "Send message failed",
          icon: "error",
          position: "top-right",
          timer: 5000,
          toast: true,
          showConfirmButton: true,
        });
      }
    });
  };

  const handleCopyButtonClicked = () => {
    console.log("Copy button clicked!");

    let sendItems = rows.filter((item) => item.sendStatus === true);
    let trackingNumbers = sendItems.map((item) => item.tracking);
    GetSumReadyToPickupPackages(
      customer.id,
      trackingNumbers,
      selectedStation.id,
      false
    ).then((res) => {
      // console.log(res);
      if (res && res.status === 200) {
        copyTextToClipboard(res.data.smsMessage);
        Swal.fire({
          text: "Copy message success",
          icon: "success",
          position: "top-right",
          timer: 1200,
          toast: true,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          text: "Copy message failed",
          icon: "error",
          position: "top-right",
          timer: 5000,
          toast: true,
          showConfirmButton: true,
        });
      }
    });
  };

  const handleSuccessButtonClicked = (_id, tracking, remark) => {
    // console.log(_id,tracking,remark);
    Swal.fire({
      icon: "question",
      title: tracking,
      text: `Update the status of this package to  end?`,
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        PatchPackageDelivery(
          _id,
          nextStation.id,
          nextStation.parcelStatus,
          true,
          true,
          "Self-pickup"
        ).then((res) => {
          let actionBy = _user.name;
          if (res.status === 200) {
            StoreTrackingLog(
              _id,
              tracking,
              nextStation.name,
              nextStation.message,
              remark,
              actionBy
            ).then((res) => {
              if (res && res.status === 200) {
                // DELETE SELECTED ROW FROM ARRAY
                setRows(() => {
                  return rows.filter((row) => row._id !== _id);
                });
                playCorrectSound();
                Swal.fire({
                  icon: "success",
                  title: `Success!`,
                  text: `Tracking no. ${tracking} has been updated`,
                  timer: 1500,
                });
              }
            });
          }
        });
      }
    });
  };

  const buttonEditHandler = (rowValue) => {
    setPackageData(rowValue);
    setEditDialogOpen(true);
  };

  useEffect(() => {
    // console.log("rows is updated", rows);
    if (rows) {
      let sendItems = rows.filter((item) => item.sendStatus === true);
      setTotalItems(sendItems.length);
      setTotalShippingFee(() => {
        return sendItems.reduce((accumulator, object) => {
          if (isAgentUser) {
            return (
              parseInt(accumulator) +
              parseInt(object.finalShippingFee ?? 0) +
              parseInt(object.amount ?? 0)
            );
          } else {
            return (
              parseInt(accumulator) +
              parseInt(object.shippingFee ?? 0) +
              parseInt(object.amount ?? 0)
            );
          }
        }, 0);
      });
    }
  }, [rows]);

  useEffect(() => {
    if (totalShippingFee > 0 && currencyExchangeRate) {
      setFeeLAK(() => {
        let num =
          parseInt(totalShippingFee) * parseInt(currencyExchangeRate.LAK);
        let roundedLAK = Math.round(num / 1000) * 1000;
        return roundedLAK.toLocaleString();
      });

      setFeeUSD(() => {
        let num =
          parseInt(totalShippingFee) / parseInt(currencyExchangeRate.USD);
        return num.toFixed(2);
      });
    }
  }, [totalShippingFee, currencyExchangeRate]);
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid marginY={1} item xl={12} md={12} xs={12}>
          <Typography variant="h4" color="secondary">
            ລູກຄ້າມາຮັບສິນຄ້າເອງຢູ່ຮ້ານ ຫຼື ໄລເດີ້ມາຮັບເຄື່ອງ
            {/* ສົ່ງຂໍ້ຄວາມຍືນຍັນເມື່ອ ຫຼື
            ເມື່ອເຮົາໄດ້ຈັດສົ່ງສິນຄ້າໃຫ້ລູກຄ້າເປັນທີ່ຮຽບຮ້ອຍແລ້ວ. */}
          </Typography>
        </Grid>
        <Grid item xl={4} md={4} xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              onInput={(e) => setSearchCustomer(e.target.value)}
              onChange={(e, value) => {
                if (value) {
                  setCustomer({
                    _id: value._id,
                    id: value.id,
                    name: value.name,
                    whatsapp: value.whatsapp,
                    pr_id: value.province.pr_id,
                  });
                  console.log(customer);
                }
              }}
              disablePortal
              id="combo-box-customer"
              options={customerList}
              isOptionEqualToValue={(customerList, customer) =>
                customerList.id === customer.id
              }
              getOptionLabel={(option) =>
                option.name + " - " + option.whatsapp || ""
              }
              renderInput={(params) => (
                <TextField {...params} label="Customer" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xl={5} md={8} xs={12}>
          <Grid container spacing={1}>
            <Grid item xl={6} md={7} xs={6}>
              <FormControl fullWidth>
                <InputLabel>Select station</InputLabel>
                <Select
                  label="Select station"
                  value={selectedStation ? selectedStation.id : ""}
                  onChange={(e) => {
                    GetStationDetailById(e.target.value).then((res) => {
                      if (res.status === 200) {
                        setSelectedStation(res.data);
                      }
                    });
                  }}
                  required
                >
                  {stationList &&
                    stationList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xl={6} md={5} xs={6}>
              <FormControl fullWidth>
                {isAgentUser ? (
                  <Button
                    variant="outlined"
                    sx={{ height: "53px" }}
                    size="large"
                    color="secondary"
                    onClick={handleCopyButtonClicked}
                    fullWidth
                  >
                    <ContentCopyIcon />
                    <Typography marginLeft={1}> Copy </Typography>
                  </Button>
                ) : (
                  <FlexBetween>
                    <Button
                      variant="outlined"
                      sx={{ height: "53px" }}
                      size="large"
                      color="secondary"
                      onClick={handleCopyButtonClicked}
                      fullWidth
                    >
                      <ContentCopyIcon />
                      <Typography marginLeft={1}> Copy </Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ marginLeft: 0.5, height: "53px" }}
                      size="large"
                      color="secondary"
                      onClick={handleSendButtonClicked}
                      fullWidth
                    >
                      <SendIcon />
                      <Typography marginLeft={1}> Send </Typography>
                    </Button>
                  </FlexBetween>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={9} md={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth disabled>
                <Chip
                  label={"Total item(s): " + totalItems}
                  variant="outlined"
                  color="secondary"
                  sx={{ fontSize: 14, height: 53 }}
                />
              </FormControl>
            </Grid>
            <Grid item xl={8} md={8} xs={12}>
              <FormControl fullWidth disabled>
                <Chip
                  label={`Fee: ฿${totalShippingFee} / ${feeLAK}Kip / ${feeUSD}USD`}
                  variant="filled"
                  color="secondary"
                  sx={{ fontSize: 14, height: 53, color: "white" }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1} width="auto">
        {rows &&
          rows.map((packageItem, index) => {
            var isSubCustomer = packageItem.isSubCustomer;
            var finalShippingFee = parseInt(packageItem.finalShippingFee ?? 0);
            var shippingFee = parseInt(packageItem.shippingFee ?? 0);
            var amount = parseInt(packageItem.amount ?? 0);
            var totalFee = isSubCustomer
              ? parseInt(amount) + parseInt(finalShippingFee)
              : parseInt(amount) + parseInt(shippingFee);

            return (
              <Grid item key={index} xl={4} md={6} xs={12} width="auto">
                <Card
                  sx={{
                    background: theme.palette.grey[100],
                  }}
                >
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor:
                            (parseInt(packageItem.shippingFee ?? 0) !== 0 &&
                              !isSubCustomer) ||
                            (isSubCustomer &&
                              parseInt(packageItem.finalShippingFee ?? 0) !== 0)
                              ? theme.palette.secondary[400]
                              : grey[500],
                        }}
                        aria-label="recipe"
                      >
                        {totalFee}
                      </Avatar>
                    }
                    action={
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={packageItem.sendStatus}
                            onChange={() => handleSendStatus(index)}
                          />
                        }
                        label={
                          packageItem.sendStatus ? "ພ້ອມສົ່ງ" : "ບໍ່ພ້ອມສົ່ງ"
                        }
                      />
                    }
                    title={packageItem.tracking}
                    subheader={moment(packageItem.updatedAt)
                      .tz("Asia/Vientiane")
                      .format("DD/MM/YYYY, hh:mm:ss")}
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      Order ID:{" "}
                      {packageItem.orderId ? packageItem.orderId : "NA / ບໍ່ມີ"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {packageItem.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={theme.palette.secondary[300]}
                    >
                      {packageItem.remark
                        ? "Remark: " + packageItem.remark
                        : ""}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton
                      aria-label="update status to success/done"
                      onClick={() =>
                        handleSuccessButtonClicked(
                          packageItem._id,
                          packageItem.tracking,
                          packageItem.remark
                        )
                      }
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Edit package"
                      onClick={() => buttonEditHandler(packageItem)}
                    >
                      <Edit />
                    </IconButton>
                    <ExpandMore
                      expand={collapseList[index]}
                      onClick={() => handleExpandClick(index)}
                      aria-expanded={collapseList[index]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse
                    id={"collapseId_" + index}
                    in={collapseList[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <CardContent>
                      <TableContainer>
                        <Table
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none",
                              // borderRight: "solid 0.1rem",
                              borderColor: theme.palette.grey[600],
                              paddingTop: 1,
                            },
                          }}
                        >
                          {packageItem.trackingLogs && (
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="right"
                                  sx={{ borderRight: 1 }}
                                >
                                  <Typography
                                    color={theme.palette.grey[600]}
                                    display="inline"
                                    sx={{ textDecoration: "underline" }}
                                  >
                                    Transactions:
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    color={theme.palette.grey[600]}
                                    display="inline"
                                    sx={{ textDecoration: "underline" }}
                                  >
                                    Description:
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          )}
                          <TableBody>
                            {packageItem.trackingLogs &&
                              packageItem.trackingLogs.map((item, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell
                                      align="right"
                                      style={{ verticalAlign: "top" }}
                                      sx={{ borderRight: 1 }}
                                    >
                                      <Typography>
                                        {moment(item.createdAt)
                                          .tz("Asia/Vientiane")
                                          .format("DD/MM/YYYY")}
                                      </Typography>
                                      <Typography>
                                        {moment(item.createdAt)
                                          .tz("Asia/Vientiane")
                                          .format("h:mm:ss A")}
                                      </Typography>
                                    </TableCell>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                      <Typography
                                        color={theme.palette.secondary[200]}
                                      >
                                        {item.station}
                                      </Typography>
                                      <Typography>
                                        {item.description}
                                      </Typography>
                                      <Typography
                                        sx={{ fontStyle: "italic" }}
                                        color={theme.palette.grey[500]}
                                      >
                                        Action by {item.actionByUser}.
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      {/* Edit Package Dialog */}
      <Box>
        <PackageEditDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          packageData={packageData}
          rows={rows}
          setRows={setRows}
          isAgentUser={isAgentUser}
        />
        {/* <Dialog
          open={editDialogOpen}
          TransitionComponent={Transition}
          maxWidth="sm"
          keepMounted
          disableEnforceFocus
          onClose={() => {
            setEditDialogOpen(false);
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <form onSubmit={handleFormSubmit}>
            <DialogTitle>
              Edit Package
              <IconButton
                aria-label="close"
                onClick={() => setEditDialogOpen(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={2}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Tracking No.</InputLabel>
                    <OutlinedInput
                      type="text"
                      label="Tracking No."
                      id="trackingNo"
                      required
                      value={tracking}
                      onChange={(e) => setTracking(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Order ID</InputLabel>
                    <OutlinedInput
                      type="text"
                      label="Order ID"
                      id="orderId"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                    <input value={_id} readOnly id="_id" hidden />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={3}
                      maxRows={9}
                      label="Description"
                      id="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      maxRows={5}
                      label="Remark"
                      id="remark"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Amount</InputLabel>
                    <OutlinedInput
                      type="number"
                      label="Amount"
                      id="amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Quantity</InputLabel>
                    <OutlinedInput
                      type="number"
                      label="Quantity"
                      id="quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Shipping Fee</InputLabel>
                    <OutlinedInput
                      type="number"
                      label="Shipping Fee"
                      id="shippingFee"
                      value={shippingFee}
                      onChange={(e) => setShippingFee(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={2}
                mb={2}
                mr={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item xl={4} md={4} xs={6}>
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="secondary"
                    >
                      Save
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog> */}
      </Box>
    </Box>
  );
}

export default NewDeliver;
