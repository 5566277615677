import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Grid,
  FormControl,
  InputBase,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Avatar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  ClearOutlined,
  Edit,
  ModeEditOutlineOutlined,
  Search,
} from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { GetPackageList, DeletePackage } from "api/packages";
import Swal from "sweetalert2";
import { playAlertSound, playCorrectSound, playIncorrectSound } from "utils";
import { isMobile } from "react-device-detect";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import { user } from "localStorage";
import PackageEditDialog from "components/PackageEditDialog";

const PackageList = () => {
  const theme = useTheme();

  // Pagination, sorting, search
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Package data
  const [packageData, setPackageData] = useState(null);
  // State for the edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // DataGrid rows and total count
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  // User and role check
  const _user = user();
  const isAgentUser = _user.role === "agent";

  // Fetch package list from API
  const RefreshPackageList = (page, pageSize, sort, search) => {
    setIsLoading(true);
    GetPackageList(page, pageSize, sort, search)
      .then((res) => {
        if (res.status === 200) {
          if (isMobile) {
            setRows((prevRows) => {
              const newRows = res.data.packages.filter(
                (newItem) =>
                  !prevRows.some(
                    (existingItem) => existingItem._id === newItem._id
                  )
              );
              return [...prevRows, ...newRows];
            });
          } else {
            setRows(res.data.packages);
          }
          setTotalCount(res.data.total);
        } else {
          console.error(res.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Edit handler: pre-fill state and open edit dialog
  const buttonEditHandler = (rowValue) => {
    setPackageData(rowValue);
    setEditDialogOpen(true);
  };

  // Delete handler
  const buttonDeleteHandler = (event, cellValues) => {
    Swal.fire({
      title: `Delete ${cellValues.row.tracking}?`,
      text: "The status of this package will be changed to deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePackage(cellValues.row._id).then((res) => {
          if (res.status === 200) {
            setRows((prevRows) =>
              prevRows.filter((item) => item._id !== cellValues.id)
            );
            playCorrectSound();
            Swal.fire({
              title: "Deleted!",
              text: res.message,
              icon: "success",
              timer: 1500,
            });
          } else if (res.status === 400) {
            playAlertSound();
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: `Status ${res.status} - ${res.data.message}`,
              showConfirmButton: true,
            });
          } else {
            playIncorrectSound();
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: res.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };

  // Define DataGrid columns (omitting details here for brevity, assume columns are defined as before)
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.5,
      valueGetter: (params) => params.row.customer && params.row.customer.name,
    },
    {
      field: "tracking",
      headerName: "Tracking",
      flex: 0.5,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 0.5,
      hide: isMobile,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "routeId",
      headerName: "Route ID",
      flex: 0.5,
      hide: isMobile,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
      hide: true,
    },
    // {
    //   field: "quantity",
    //   headerName: "QTY",
    //   flex: 0.5,
    // },

    {
      field: "status",
      headerName: "Status",
      flex: 0.3,
      hide: true,
    },
    {
      field: "station",
      headerName: "Station",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "isCOD",
      headerName: "COD",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isCOD ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "shippingFee",
      headerName: "ຄ່າບໍລິການ",
      flex: 0.3,
    },
    {
      field: "isForwardRequired",
      headerName: "ສົ່ງຕໍ່",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isForwardRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isDeliveryRequired",
      headerName: "ສົ່ງຢູ່ບ້ານ",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isDeliveryRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isSelfOrder",
      headerName: "ສັ່ງເອງ",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSelfOrder ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isSubCustomer",
      headerName: "ລູກຄ້າຕົວແທນ",
      flex: 0.3,
      hide: false,
      renderCell: (cellValues) => {
        return cellValues.row.isSubCustomer ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "agentId",
      headerName: "ຕົວແທນ",
      flex: 0.3,
      hide: false,
    },
    {
      field: "finalShippingFee",
      headerName: "Final Fee",
      flex: 0.3,
      hide: false,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.7,
      hide: isMobile,
      valueGetter: (params) =>
        moment(params.row.updatedAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.7,
      hide: true,
      valueGetter: (params) =>
        moment(params.row.createdAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 0.2,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outline"
            onClick={() => {
              buttonEditHandler(cellValues.row);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ModeEditOutlineOutlined />
          </IconButton>
        );
      },
    },
    {
      field: "del",
      headerName: "Delete",
      flex: 0.2,
      hide: true,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={(event) => buttonDeleteHandler(event, cellValues)}
          >
            <ClearOutlined />
          </IconButton>
        );
      },
    },
  ];

  // Debounce search
  let timer;
  const setSearchWithDelaying = (val) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => setSearch(val), 1000);
  };

  // Infinite scroll for mobile
  useEffect(() => {
    if (!isMobile) return;
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1;
      if (bottom && !isLoading && rows.length < totalCount) {
        setPage((prevPage) => prevPage + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, rows.length, totalCount]);

  // Refresh package list when page, pageSize, sort, or search change
  useEffect(() => {
    RefreshPackageList(page, pageSize, sort, search);
  }, [page, pageSize, sort, search]);

  // Reset rows when search changes
  useEffect(() => {
    setRows([]);
    setPage(0);
    RefreshPackageList(0, pageSize, sort, search);
  }, [search]);

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isMobile ? (
        <Box
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading}
            getRowId={(row) => row._id}
            rows={rows || []}
            columns={columns}
            rowCount={totalCount || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch },
            }}
          />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={1.5} width="auto">
            <Grid item xl={4} md={6} xs={12}>
              <FlexBetween
                backgroundColor={theme.palette.background.alt}
                borderRadius="9px"
                gap="3rem"
                p="0.7rem 1.5rem"
              >
                <InputBase
                  placeholder="Search..."
                  onChange={(e) => setSearchWithDelaying(e.target.value)}
                />
                <IconButton>
                  <Search />
                </IconButton>
              </FlexBetween>
            </Grid>
            {rows &&
              rows.map((packageItem, index) => (
                <Grid item key={index} xl={4} md={6} xs={12} width="auto">
                  <Card sx={{ background: theme.palette.grey[100] }}>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{
                            bgcolor:
                              parseInt(packageItem.shippingFee) !== 0
                                ? theme.palette.secondary[400]
                                : theme.palette.grey[500],
                          }}
                          aria-label="package"
                        >
                          {isAgentUser
                            ? parseInt(packageItem.amount) +
                              parseInt(packageItem.finalShippingFee)
                            : parseInt(packageItem.amount) +
                              parseInt(packageItem.shippingFee)}
                        </Avatar>
                      }
                      title={packageItem.tracking}
                      subheader={moment(packageItem.updatedAt)
                        .tz("Asia/Vientiane")
                        .format("DD/MM/YYYY, hh:mm:ss")}
                      action={
                        <FormControl sx={{ mr: 1, mt: 0.5 }}>
                          <Typography
                            variant="body1"
                            color={theme.palette.secondary[200]}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            {packageItem.customer && packageItem.customer.name}
                            {packageItem.isSubCustomer &&
                              ` - (${packageItem.agentId})`}
                          </Typography>
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[500]}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            {packageItem.customer &&
                              packageItem.customer.whatsapp}
                          </Typography>
                        </FormControl>
                      }
                    />
                    <CardContent>
                      <FlexBetween sx={{ marginTop: -2 }}>
                        <Typography variant="body2" color="text.secondary">
                          Order ID:{" "}
                          {packageItem.orderId
                            ? packageItem.orderId
                            : "NA / ບໍ່ມີ"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          at {packageItem.station} ({packageItem.routeId})
                        </Typography>
                      </FlexBetween>
                      <Typography variant="body2" color="text.secondary">
                        {packageItem.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.secondary[300]}
                      >
                        {packageItem.remark
                          ? "Remark: " + packageItem.remark
                          : ""}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ flexGrow: 1, marginLeft: 1 }}>
                        <Typography color={theme.palette.grey[500]}>
                          #{index + 1}
                        </Typography>
                      </Box>
                      <IconButton
                        aria-label="Edit package"
                        onClick={() => buttonEditHandler(packageItem)}
                      >
                        <Edit />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      {/* Edit Package Dialog */}
      <PackageEditDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        packageData={packageData}
        rows={rows}
        setRows={setRows}
      />
    </Box>
  );
};

export default PackageList;
