import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  IconButton,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

const isValidGoogleMapURL = (url) => {
  const regex =
    /^(https?:\/\/)?(www\.)?(google\.com|maps\.google\.com|goo\.gl|maps\.app\.goo\.gl)\/([a-zA-Z0-9@:%_+.~#?&//=]*)$/;
  return regex.test(url);
};

const DeliveryAddressDialog = ({ open, onClose, onSave }) => {
  const initialState = {
    id: uuidv4(),
    map: "",
    title: "",
    description: "",
    isDefault: true,
  };

  const [address, setAddress] = useState(initialState);
  const addressTitleOptions = ["Home", "Office", "Apartment", "Shop", "Other"];
  const [mapError, setMapError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = (e) => {
    let isValid = true;
    if (!isValidGoogleMapURL(address.map)) {
      setMapError(true);
      isValid = false;
    } else {
      setMapError(false);
    }

    if (address.title.trim() === "") {
      setTitleError(true);
      isValid = false;
    } else {
      setTitleError(false);
    }

    if (address.description.trim() === "") {
      setDescriptionError(true);
      isValid = false;
    } else {
      setDescriptionError(false);
    }

    if (isValid) {
      onSave(address);
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setAddress(initialState);
      setMapError(false);
      setTitleError(false);
      setDescriptionError(false);
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        Add a New Delivery Address
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid mt={1} container spacing={2}>
          <Grid item xl={12} md={12} xs={12}>
            <TextField
              required
              fullWidth
              label="Google Map URL"
              name="map"
              value={address.map}
              onChange={handleChange}
              variant="outlined"
              error={
                mapError ||
                (!isValidGoogleMapURL(address.map) && address.map !== "")
              }
              helperText={
                mapError
                  ? "Please enter a valid Google Maps URL."
                  : !isValidGoogleMapURL(address.map) && address.map !== ""
                  ? "Invalid Google Maps URL"
                  : ""
              }
            />
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <FormControl component="fieldset" error={titleError}>
              <FormLabel component="legend" >Address Type</FormLabel>
              <RadioGroup
                aria-label="addressType"
                name="title"
                value={address.title}
                onChange={handleChange}
                row={true}
                required={true}
              >
                {addressTitleOptions.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "secondary.main", // Set color to secondary
                          },
                        }}
                      />
                    }
                    label={option}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>
                {titleError && "Please select an address type."}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={address.description}
              required={true}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={3}
              error={descriptionError}
              helperText={descriptionError && "Please enter a description."}
            />
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={address.isDefault}
                  onChange={handleChange}
                  name="isDefault"
                  color="secondary"
                />
              }
              label="Default ກຳໜົດເປັນທີ່ຢູ່ຫຼັກ"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box margin={2} width={1000}>
          <Button
            fullWidth
            onClick={handleSave}
            color="secondary"
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryAddressDialog;
