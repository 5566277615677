// ForwardAddressList.jsx
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, useTheme, Button, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ForwardAddressDialog from "components/ForwardAddressDialog"; // Import the dialog

const ForwardAddressList = ({
  forwardAddresses,
  setForwardAddresses,
  isForwardRequired,
}) => {
  const theme = useTheme();
  const [forwardAddrDialogOpen, setForwardAddrDialogOpen] = useState(false);

  const handleOpenForwardAddressDialog = () => setForwardAddrDialogOpen(true);
  const handleCloseForwardAddressDialog = () => setForwardAddrDialogOpen(false);

  const handleSaveForwardAddress = (newAddress) => {
    if (forwardAddresses.length >= 5) {
      alert("You can only add up to 5 delivery addresses.");
      return;
    }

    setForwardAddresses((prevAddresses) => {
      const updatedAddresses = prevAddresses.map((address) => ({
        ...address,
        isDefault: false,
      }));
      return [newAddress, ...updatedAddresses];
    });
    setForwardAddrDialogOpen(false); //close dialog after save
  };

  const handleRemoveForwardAddresses = (id) => {
    setForwardAddresses((prevAddresses) => {
      const updatedAddresses = prevAddresses.filter(
        (address) => address.id !== id
      );
      if (
        updatedAddresses.length > 0 &&
        prevAddresses.find((address) => address.id === id).isDefault
      ) {
        updatedAddresses[0].isDefault = true;
      }
      return updatedAddresses;
    });
  };

  const buttonDisableEnableHandler = (value) => {
    const targetId = value.id;
    if (forwardAddresses.length === 1 && forwardAddresses[0].isDefault) {
      return;
    }
    setForwardAddresses((prevAddresses) => {
      let newAddresses = prevAddresses.map((address) => {
        if (address.id === targetId) {
          return { ...address, isDefault: !value.isDefault };
        }
        return address;
      });

      const targetAddress = newAddresses.find((addr) => addr.id === targetId);
      if (targetAddress && targetAddress.isDefault) {
        newAddresses = newAddresses.map((address) => {
          if (address.id !== targetId && address.isDefault) {
            return { ...address, isDefault: false };
          }
          return address;
        });
      }

      return newAddresses;
    });
  };

  const forwardColumns = [
    { field: "id", headerName: "ID", flex: 1, hide: true },
    {
      field: "no",
      headerName: "No.",
      flex: 0.5,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
    },
    { field: "logisticCompany", headerName: "Company", flex: 2 },
    { field: "branch", headerName: "Branch", flex: 2 },
    { field: "description", headerName: "Description", flex: 4 },
    {
      field: "isDefault",
      headerName: "Default",
      flex: 1,
      renderCell: (cellValues) => {
        return cellValues.row.isDefault ? (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={() => buttonDisableEnableHandler(cellValues.row)}
          >
            <ToggleOnIcon color="secondary" />
          </IconButton>
        ) : (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={() => buttonDisableEnableHandler(cellValues.row)}
          >
            <ToggleOffIcon />
          </IconButton>
        );
      },
    },
    {
      field: "remove",
      headerName: "Remove",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleRemoveForwardAddresses(params.row.id)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {isForwardRequired && (
        <Grid container spacing={1}>
          <Grid item xl={12} md={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              sx={{ px: 5, mb: 1 }}
              onClick={handleOpenForwardAddressDialog}
            >
              New forward address
            </Button>
            <ForwardAddressDialog
              open={forwardAddrDialogOpen}
              onClose={handleCloseForwardAddressDialog}
              onSave={handleSaveForwardAddress}
            />
          </Grid>
        </Grid>
      )}
      <DataGrid
        autoHeight
        columns={forwardColumns}
        getRowId={(row) => row.id}
        rows={forwardAddresses || []}
        rowCount={forwardAddresses ? forwardAddresses.length : 0}
        hideFooter={true}
      />
    </>
  );
};

export default ForwardAddressList;
