import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import TabsNavigator from "components/TabsNavigator";
import CurrencyExchangeRate from "./currencyExchangeRate";
import WhatsAppLogin from "./whatsAppLogin";


const Settings = () => {

  const tabItems = [
    {
      text: "Currency Exchage Rate",
      component: <CurrencyExchangeRate/>
    },
    {
      text: "WhatsApp Login",
      component: <WhatsAppLogin/>
    }
  ];

  return (
    <Box m="0 1rem">
      <Header title="Settings"/>
      <TabsNavigator tabItems={tabItems} />
    </Box>
  );
};

export default Settings;
