import axios from "axios";
import { api } from "./config";

export async function StorePackage(
  tracking,
  description = "",
  orderId = "",
  customerId = "",
  amount = 0,
  quantity = 1,
  shippingFee = 0,
  routeId,
  status = "Started",
  remark = "",
  paymentStatus = false,
  station = "STD",
  whatsappStatus,
  isCOD,
  isDeliveryRequired,
  isForwardRequired,
  isSubCustomer,
  agentId,
  level,
  isSelfOrder,
  forwardAddress,
  deliveryAddress,
  finalShippingFee
) {
  try {
    let upperCaseTracking = tracking.toUpperCase();
    const response = await api()
      .post("/package/store", {
        tracking: upperCaseTracking,
        description,
        orderId,
        customerId,
        amount,
        quantity,
        shippingFee: parseInt(shippingFee),
        routeId,
        status,
        remark,
        paymentStatus,
        station,
        whatsappStatus,
        isCOD,
        isDeliveryRequired,
        isForwardRequired,
        isSubCustomer,
        agentId,
        level,
        isSelfOrder,
        forwardAddress,
        deliveryAddress,
        finalShippingFee,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetPackageList(page, pageSize, sort, search) {
  try {
    const response = await api().get("/package/list", {
      params: {
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetUnassignedPackageList(station, route) {
  try {
    const response = await api().get(
      "/package/list-unassigned-package-by-station-and-route",
      {
        params: {
          nextStationId: station,
          routeId: route,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetMatchPackageList(station, route) {
  try {
    const response = await api().get(
      "/package/list-match-package-by-station-and-route",
      {
        params: {
          nextStationId: station,
          routeId: route,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetMatchPackageListByCustomer(customerId) {
  try {
    const response = await api().get("/package/match-package-by-customer", {
      params: {
        customerId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetPackagesSumByStatus(customerId) {
  try {
    const response = await api().get("/package/sum-by-status", {
      params: {
        customerId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function GetPackagesByCustomerId(customerId, sort, search) {
  try {
    const response = await api().get("/package/customer", {
      params: {
        customerId,
        sort: JSON.stringify(sort),
        search,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function GetPackagesByStationAndDate(
  fromDate = "",
  toDate = "",
  station = ""
) {
  // from & to format is YYYY-MM-DD
  // station - if empty will return all stations
  try {
    const response = await api().get("/package/list-by-station-and-date", {
      params: {
        fromDate,
        toDate,
        station,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetSumPackagesForWhatsApp(station) {
  try {
    const response = await api().get("/package/sumpackages", {
      params: {
        station,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetSumPackagesForAgent() {
  try {
    const response = await api().get("/package/sumpackages-agent");
    return response;
  } catch (error) {
    console.log(error);
  }
}
export async function GetSumPackagesForBulkMove(
  routeId,
  nextStationId,
  search
) {
  try {
    const response = await api().get("/package/sumpackages-bulkmove", {
      params: {
        routeId,
        nextStationId,
        search,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetSumPackagesByStation(stationId) {
  try {
    const response = await api().get("/package/sumpackages-by-station", {
      params: {
        stationId,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetSumPackagesForForwardPackages(stationId) {
  try {
    const response = await api().get(
      "/package/sumpackages-for-forward-packages",
      {
        params: {
          stationId,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetSumPackagesForHomeDeliveryPackages(stationId) {
  try {
    const response = await api().get(
      "/package/sumpackages-for-home-delivery-packages",
      {
        params: {
          stationId,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function GetSumPackagesForDelivered(station, search, cancelToken) {
  try {
    const response = await api().get("/package/sumpackages-delivered", {
      params: {
        station,
        search,
      },
      cancelToken,
    });

    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("API call cancelled");
    } else {
      console.log(error);
    }
  }
}

export async function GetPackageDetailByTracking(tracking) {
  try {
    const response = await api().get("/package/tracking", {
      params: {
        tracking: tracking,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetReadyToPickupPackages(customerId, stationId) {
  try {
    const response = await api().get(
      "/package/ready-to-pickup?customerId=" +
        customerId +
        "&stationId=" +
        stationId
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function GetSumReadyToPickupPackages(
  customerId,
  trackingNumbers,
  stationId,
  sendMessage = false
) {
  try {
    console.log(customerId, trackingNumbers);
    const response = await api().post("/package/sum-ready-to-pickup", {
      customerId,
      trackingNumbers,
      stationId,
      sendMessage,
    });

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function DeletePackage(id) {
  try {
    const url = "/package/delete/" + id;
    console.log(url);
    const responseData = await api()
      .delete("/package/delete/" + id)
      .then((res) => res.data)
      .catch((error) => error.response);
    return responseData;
  } catch (error) {
    console.error(error);
  }
}

export async function scanPackage(tracking, nextStationId, actionType) {
  const responseData = await api()
    .post("/package/scan", {
      tracking,
      nextStationId,
      actionType,
    })
    .then((res) => res)
    .catch((error) => error.response);
  return responseData;
}

export async function matchPackage(originTrackingNumber, newTrackingNumber) {
  const responseData = await api()
    .post("/package/match-package", {
      originTrackingNumber,
      newTrackingNumber,
    })
    .then((res) => res)
    .catch((error) => error.response);
  return responseData;
}

export async function PatchPackage(
  _id,
  tracking,
  description,
  orderId,
  customerId,
  amount,
  quantity,
  shippingFee,
  routeId,
  station,
  status,
  remark,
  paymentStatus,
  whatsappStatus,
  isCOD,
  isDeliveryRequired,
  isForwardRequired,
  isSubCustomer,
  agentId,
  level,
  isSelfOrder,
  finalShippingFee,
  agentWhatsappStatus,
  forwardAddress,
  deliveryAddress
) {
  try {
    let upperCaseTracking = tracking.toUpperCase();
    const responseData = await api()
      .patch("/package/patch", {
        _id,
        tracking: upperCaseTracking,
        description,
        orderId,
        customerId,
        amount,
        quantity,
        shippingFee,
        routeId,
        station,
        status,
        remark,
        paymentStatus,
        whatsappStatus,
        isCOD,
        isDeliveryRequired,
        isForwardRequired,
        isSubCustomer,
        agentId,
        level,
        isSelfOrder,
        finalShippingFee,
        agentWhatsappStatus,
        forwardAddress,
        deliveryAddress,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return responseData;
  } catch (error) {
    console.error(error);
  }
}
export async function PatchPackageDelivery(
  _id,
  station,
  status,
  paymentStatus,
  whatsappStatus,
  deliveryMethod
) {
  try {
    const response = await api()
      .patch("/package/patch", {
        _id,
        station,
        status,
        paymentStatus,
        whatsappStatus,
        deliveryMethod,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function PatchPackageSomeInfo(
  _id,
  tracking,
  station,
  status,
  remark,
  paymentStatus,
  whatsappStatus
) {
  try {
    const response = await api()
      .patch("/package/patch", {
        _id,
        tracking,
        station,
        status,
        remark,
        paymentStatus,
        whatsappStatus,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function PatchPackageAgentWhatsappStatus(
  _id,
  tracking,
  agentWhatsappStatus,
  whatsappStatus = false,
  paymentStatus = false
) {
  try {
    const response = await api()
      .patch("/package/patch", {
        _id,
        tracking,
        agentWhatsappStatus,
        whatsappStatus,
        paymentStatus,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function PatchShippingFee(
  _id,
  tracking,
  orderId,
  description,
  remark,
  amount,
  quantity,
  shippingFee
) {
  try {
    let upperCaseTracking = tracking.toUpperCase();
    const response = await api()
      .patch("/package/patch", {
        _id,
        tracking: upperCaseTracking,
        orderId,
        description,
        remark,
        amount,
        quantity,
        shippingFee,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function PatchMatchPackage(_id, tracking, orderId, description) {
  try {
    let upperCaseTracking = tracking.toUpperCase();
    const response = await api()
      .patch("/package/patch", {
        _id,
        tracking: upperCaseTracking,
        orderId,
        description,
      })
      .then((res) => res.data)
      .catch((error) => error.response);
    return response;
  } catch (error) {
    console.log(error);
  }
}
