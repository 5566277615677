import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { DeleteCustomer, GetCustomersList } from "api/customers";
import {
  Box,
  useTheme,
  IconButton,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CustomerEditDialog from "components/CustomerEditDialog";

const CustomerList = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");

  // values to set in DataGrid
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // ... (pagination, sorting, search state and related useEffects)

  function RefreshCutomersList(search, page, pageSize, sort) {
    // ... (your existing RefreshCutomersList function)
    setIsLoading(true);
    GetCustomersList(search, page, pageSize, sort, true, true).then((res) => {
      if (res.status === 200) {
        setRows(res.data.customers);
        setTotalCount(res.data.total);
        setIsLoading(false);
      } else {
        console.log(res.message);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    RefreshCutomersList(search, page, pageSize, sort);
  }, []);

  useEffect(() => {
    RefreshCutomersList(search, page, pageSize, sort);
  }, [page, pageSize, sort, search]);

  const buttonEditHandler = (event, cellValues) => {
    setEditingCustomer(cellValues.row);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditingCustomer(null);
  };

  const buttonDeleteHandler = (event, cellValues) => {
    Swal.fire({
      title: `Delete ${cellValues.row.name} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCustomer(cellValues.row._id).then((res) => {
          if (res.status === 200) {
            // DELETE SELECTED ROW FROM ARRAY "data.customers"
            setRows(() => {
              return rows.filter((item) => item._id !== cellValues.id);
            });

            Swal.fire({
              title: "Deleted!",
              text: res.message,
              icon: "success",
              timer: 1500,
            });
          } else if (res.status === 400) {
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: `Status ${res.status} - ${res.data.message}`,
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: res.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };

  const columns = [
    // ... (all your DataGrid columns)
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <Link
            component="button"
            color="secondary"
            underline="hover"
            onClick={() => navigate(`/customer/${cellValues.row.id}`)}
          >
            {cellValues.row.name}
          </Link>
        );
      },
    },
    {
      field: "whatsapp",
      headerName: "WhatsApp",
      flex: 0.7,
    },
    {
      field: "default_route",
      headerName: "Default Route",
      flex: 0.5,
      hide: false,
    },
    {
      field: "isCOD",
      headerName: "COD",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isCOD ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isDeliveryRequired",
      headerName: "ຈັດສົ່ງຮອດເຮືອນ",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isDeliveryRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    { field: "deliveryAddresses", headerName: "Location", hide: true, flex: 1 },

    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueGetter: (params) => {
        let address;
        if (isMobile) {
          address = params.row.province.pr_name;
        } else {
          address = params.row.province.pr_name;
          address += params.row.district.dt_name
            ? ", " + params.row.district.dt_name
            : "";
        }

        return address;
      },
      hide: true,
    },
    {
      field: "isForwardRequired",
      headerName: "ຝາກຂົນສົ່ງຕໍ່",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isForwardRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    { field: "forwardAddresses", headerName: "Addresses", hide: true, flex: 1 },

    {
      field: "remark",
      headerName: "Remark",
      flex: 1,
      hide: isMobile,
    },
    {
      field: "isSelfOrder",
      headerName: "ສັ່ງເຄື່ອງເອງ",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSelfOrder ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isSubCustomer",
      headerName: "ລູກຄ້າຂອງຕົວແທນ",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSubCustomer ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "agentId",
      headerName: "ລະຫັດຕົວແທນ",
      flex: 0.5,
      hide: isMobile,
    },
    {
      field: "validflag",
      headerName: "Validflag",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.validflag ? (
          <ToggleOnIcon color="secondary" />
        ) : (
          <ToggleOffIcon />
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.2,
      renderCell: (cellValues) => (
        <IconButton
          variant="outline"
          onClick={(event) => buttonEditHandler(event, cellValues)}
          sx={{ "&:hover": { color: theme.palette.warning.main } }}
        >
          <ModeEditOutlineIcon />
        </IconButton>
      ),
    },
    {
      field: "del",
      headerName: "Delete",
      flex: 0.3,
      hide: true,
      renderCell: (cellValues) => (
        <IconButton
          variant="outline"
          sx={{ "&:hover": { color: theme.palette.error.main } }}
          onClick={(event) => buttonDeleteHandler(event, cellValues)}
        >
          <ClearOutlinedIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box
      height="80vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DataGrid
        loading={isLoading}
        getRowId={(row) => row._id}
        rows={rows || []}
        columns={columns}
        rowCount={totalCount || 0}
        rowsPerPageOptions={[20, 50, 100]}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        sortingMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onSortModelChange={(newSortModel) => setSort(...newSortModel)}
        components={{ Toolbar: DataGridCustomToolbar }}
        componentsProps={{
          toolbar: { searchInput, setSearchInput, setSearch },
        }}
      />

      <CustomerEditDialog
        open={editDialogOpen}
        customerData={editingCustomer}
        onClose={handleEditDialogClose}
        rows={rows}
        setRows={setRows}
      />
    </Box>
  );
};

export default CustomerList;
