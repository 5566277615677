// PackageEditDialog.jsx
import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Switch,
  Divider,
  Button,
  Typography,
  Slide,
} from "@mui/material";
import { GetAgentList } from "api/agent";
import { CloseOutlined } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import {
  getScanText,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import { GetCustomersList } from "api/customers";
import { GetRouteList, GetRouteDetail } from "api/routes";
import { isMobile } from "react-device-detect";
import { PatchPackage } from "api/packages";
import Swal from "sweetalert2";
import { user } from "localStorage";

// Transition component for dialog animations
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Define the props for the PackageEditDialog
const PackageEditDialog = ({
  open,
  onClose,
  packageData,
  rows,
  setRows,
  // Data and state handlers:
}) => {
  // Package data
  const [packageId, setPackageId] = useState("");
  const [tracking, setTracking] = useState("");
  const [description, setDescription] = useState("");
  const [orderId, setOrderId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [routeId, setRouteId] = useState("");
  const [customer, setCustomer] = useState(null);
  const [shippingFee, setShippingFee] = useState(0);
  const [status, setStatus] = useState("");
  const [station, setStation] = useState("");
  const [whatsappStatus, setWhatsappStatus] = useState(false);
  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isDeliveryRequired, setIsDeliveryRequired] = useState(false);
  const [isCOD, setIsCOD] = useState(false);
  const [isSubCustomer, setIsSubCustomer] = useState(false);
  const [level, setLevel] = useState("1");
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isForwardRequired, setIsForwardRequired] = useState(false);
  const [isSelfOrder, setIsSelfOrder] = useState(false);
  const [finalShippingFee, setFinalShippingFee] = useState(0);
  const [agentWhatsappStatus, setAgentWhatsappStatus] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [forwardAddress, setForwardAddress] = useState(null);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [forwardAddresses, setForwardAddresses] = useState([]);

  // Front-end data
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const searchCustomerTimeout = useRef(null); // Ref to hold the timeout ID
  const [routeList, setRouteList] = useState([]);
  const [stationList, setStationList] = useState(null);
  const [isNewCustomerSelected, setIsNewCustomerSelected] = useState(false);
  const _user = user();
  const isAgentUser = _user.role === "agent";

  const handleFormSubmited = (e) => {
    e.preventDefault();
    const customerId = customer ? customer.id : "";
    PatchPackage(
      packageId,
      tracking,
      description,
      orderId,
      customerId,
      amount,
      quantity,
      shippingFee,
      routeId,
      station,
      status,
      remark,
      paymentStatus,
      whatsappStatus,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isSubCustomer,
      agentId,
      level,
      isSelfOrder,
      finalShippingFee,
      agentWhatsappStatus,
      forwardAddress,
      deliveryAddress
    ).then((res) => {
      if (res.status === 200) {
        const updatedRows = rows.map((pkg) =>
          pkg._id === packageId
            ? {
                ...pkg,
                tracking,
                description,
                orderId,
                customer,
                amount,
                quantity,
                shippingFee,
                routeId,
                station,
                status,
                remark,
                paymentStatus,
                whatsappStatus,
                isCOD,
                isDeliveryRequired,
                isForwardRequired,
                isSubCustomer,
                agentId,
                level,
                isSelfOrder,
                finalShippingFee,
                forwardAddress,
                deliveryAddress,
              }
            : pkg
        );
        setRows(updatedRows);
        onClose();
        // clearAllState();
        playCorrectSound();
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
      } else if (res.status === 400) {
        playAlertSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  // Set defaults when a new customer is selected
  useEffect(() => {
    // Set delivery & forward addresses from customer
    if (customer) {
      if ("forwardAddresses" in customer)
        setForwardAddresses(customer.forwardAddresses);
      if ("deliveryAddresses" in customer)
        setDeliveryAddresses(customer.deliveryAddresses);
    }

    if (isNewCustomerSelected && customer) {
      if ("default_route" in customer) setRouteId(customer.default_route);
      if ("isCOD" in customer) setIsCOD(customer.isCOD);
      if ("isDeliveryRequired" in customer)
        setIsDeliveryRequired(customer.isDeliveryRequired);
      if ("isForwardRequired" in customer)
        setIsForwardRequired(customer.isForwardRequired);
      if ("isSubCustomer" in customer) setIsSubCustomer(customer.isSubCustomer);
      if ("isSelfOrder" in customer) setIsSelfOrder(customer.isSelfOrder);
      if ("agentId" in customer) setAgentId(customer.agentId);
      if ("level" in customer) setLevel(customer.level);

      setIsNewCustomerSelected(false);
    }
  }, [isNewCustomerSelected, customer]);

  useEffect(() => {
    if (packageData?.forwardAddress && forwardAddresses.length > 0) {
      const matchAddress = forwardAddresses.find(
        (address) => address.id === packageData.forwardAddress.id
      );
      setForwardAddress(matchAddress || null);
    } else if (packageData && forwardAddresses.length === 0) {
      setForwardAddress(null);
    }
  }, [forwardAddresses, packageData]);

  useEffect(() => {
    if (packageData?.deliveryAddress && deliveryAddresses.length > 0) {
      const matchAddress = deliveryAddresses.find(
        (address) => address.id === packageData.deliveryAddress.id
      );
      setDeliveryAddress(matchAddress || null);
    } else if (packageData && deliveryAddresses.length === 0) {
      setDeliveryAddress(null);
    }
  }, [packageData, deliveryAddresses]);

  useEffect(() => {
    if (packageData?.station && stationList?.length > 0) {
      const matchStation = stationList.find(
        (station) => station.station === packageData.station
      );
      setStation(matchStation?.station || "");
    } else if (packageData && stationList?.length === 0) {
      setStation("");
    }
  }, [stationList, packageData]);

  useEffect(() => {
    if (packageData?.customer && customerList?.length > 0) {
      const matchCustomer = customerList.find(
        (customer) => customer.id === packageData.customer.id
      );
      setCustomer(matchCustomer || null);
    } else if (packageData && customerList?.length === 0) {
      setCustomer(null);
    }
  }, [packageData, customerList]);

  // Fetch route list
  useEffect(() => {
    GetRouteList().then((res) => {
      if (res.data) setRouteList(res.data);
    });
  }, []);

  // Set state value
  useEffect(() => {
    // clearAllState();
    if (packageData) {
      // console.log("🚀 ~ useEffect ~ packageData:", packageData)
      const {
        _id,
        tracking,
        description,
        customer,
        orderId,
        amount,
        quantity,
        shippingFee,
        routeId,
        status,
        remark,
        paymentStatus,
        whatsappStatus,
        isCOD,
        isDeliveryRequired,
        isForwardRequired,
        isSubCustomer,
        agentId,
        level,
        isSelfOrder,
        finalShippingFee,
        agentWhatsappStatus,
      } = packageData;
      setSearchCustomer(customer && customer.whatsapp);
      // console.log("first setSearchCustomer")
      setPackageId(_id);
      setTracking(tracking);
      setDescription(description);
      setOrderId(orderId);
      setAmount(amount);
      setQuantity(quantity);
      setShippingFee(shippingFee);
      setStatus(status);
      setRemark(remark);
      setPaymentStatus(paymentStatus);
      setWhatsappStatus(whatsappStatus);
      setRouteId(routeId);
      setIsCOD(isCOD);
      setIsDeliveryRequired(isDeliveryRequired);
      setIsForwardRequired(isForwardRequired);
      setIsSubCustomer(isSubCustomer);
      setAgentId(agentId);
      setLevel(level);
      setIsSelfOrder(isSelfOrder);
      setFinalShippingFee(finalShippingFee ?? 0);
      setAgentWhatsappStatus(agentWhatsappStatus);
    }
  }, [packageData]);

  // Fetch station list based on route selection
  useEffect(() => {
    if (routeId) {
      GetRouteDetail(routeId).then((res) => {
        if (res.status === 200) setStationList(res.data.navigator);
      });
    }
  }, [routeId]);

  // useEffect(() => {
  //   if (searchCustomer && searchCustomer !== "") {
  //     GetCustomersList(searchCustomer).then((res) => {
  //       if (res.status === 200) {
  //         setCustomerList(res.data.customers);
  //       } else {
  //         console.error(res.message);
  //       }
  //     });
  //   }
  // }, [searchCustomer]);

  useEffect(() => {
    if (searchCustomer && searchCustomer !== "") {
      // Clear any existing timeout
      if (searchCustomerTimeout.current) {
        clearTimeout(searchCustomerTimeout.current);
      }

      // Set a new timeout
      searchCustomerTimeout.current = setTimeout(() => {
        GetCustomersList(searchCustomer).then((res) => {
          if (res.status === 200) {
            setCustomerList(res.data.customers);
          } else {
            console.error(res.message);
          }
        });
      }, 500); // 500ms delay
    } else {
      setCustomerList([]); // Clear customer list if searchCustomer is empty
    }

    // Cleanup function to clear the timeout if the component unmounts or searchCustomer changes
    return () => {
      if (searchCustomerTimeout.current) {
        clearTimeout(searchCustomerTimeout.current);
      }
    };
  }, [searchCustomer]);

  // Fetch agent list for sub-customers
  useEffect(() => {
    if (isSubCustomer) {
      GetAgentList().then((res) => {
        if (res.data) setAgentList(res.data);
      });
    } else {
      setAgentId("");
    }
  }, [isSubCustomer]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="md"
      fullScreen={isMobile}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={handleFormSubmited}>
        <DialogTitle>
          Edit Package
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} mt={2}>
            {/* Customer Autocomplete */}
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(e, value) => {
                    setCustomer(value || null);
                  }}
                  disablePortal
                  id="combo-box-customer"
                  options={customerList}
                  value={customer}
                  isOptionEqualToValue={(option, value) =>
                    value && value.id === option.id
                  } // Custom equality check
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.whatsapp}` || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      onInput={(e) => setSearchCustomer(e.target.value)}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {/* Route Selection */}
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Route</InputLabel>
                <Select
                  id="route"
                  value={routeId || ""}
                  label="Route"
                  onChange={(event) => setRouteId(event.target.value)}
                  required
                >
                  {routeList &&
                    routeList.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {id} ({name})
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Station Selection */}
            <Grid item xl={4} md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Station</InputLabel>
                <Select
                  fullWidth
                  label="Station"
                  value={stationList ? station : ""}
                  onChange={(e) => setStation(e.target.value)}
                  // disabled={currentStation?.finalStation}
                >
                  {stationList &&
                    stationList.map(
                      ({ _id, station, stationName, nextStationName }) => (
                        <MenuItem key={_id} value={station}>
                          <Grid container>
                            <Grid item xl={5} md={5} xs={5}>
                              {stationName}
                            </Grid>
                            <Grid item xl={2} md={2} xs={2}>
                              {" => "}
                            </Grid>
                            <Grid item xl={5} md={5} xs={5}>
                              {nextStationName || ""}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            </Grid>
            {/* Whatsapp Status */}
            <Grid item xl={3} md={3} xs={6}>
              <InputLabel>Whatsapp Status</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={whatsappStatus || false}
                    onChange={() => setWhatsappStatus(!whatsappStatus)}
                  />
                }
                label={whatsappStatus ? "ສົ່ງແລ້ວ" : "ຍັງບໍ່ໄດ້ສົ່ງ"}
              />
            </Grid>
            {/* Delivery Status */}
            <Grid item xl={3} md={3} xs={6}>
              <InputLabel>Delivery Status</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={paymentStatus || false}
                    onChange={() => setPaymentStatus(!paymentStatus)}
                  />
                }
                label={paymentStatus ? "ສົ່ງແລ້ວ" : "ຍັງບໍ່ໄດ້ສົ່ງ"}
              />
            </Grid>
            {/* COD Option */}
            <Grid item xl={3} md={3} xs={6}>
              <InputLabel>COD ຈ່າຍປາຍທາງ</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isCOD || false}
                    onChange={() => setIsCOD(!isCOD)}
                  />
                }
                label={isCOD ? "ເກັບ" : "ບໍ່ເກັບ"}
              />
            </Grid>
            {/* Self Order */}
            <Grid item xl={3} md={3} xs={6}>
              <InputLabel>ລູກຄ້າສັ່ງເຄື່ອງເອງ</InputLabel>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isSelfOrder || false}
                    onChange={() => setIsSelfOrder(!isSelfOrder)}
                  />
                }
                label={isSelfOrder ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
              />
            </Grid>
            <Grid container spacing={0} margin={1}>
              <Grid item xl={6} md={6} xs={12}>
                <InputLabel>
                  Home Delivery ຈັດສົ່ງສິນຄ້າຮອດເຮືອນລູກຄ້າ
                </InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!customer} // Disable if customer is null/undefined
                      color="secondary"
                      checked={isDeliveryRequired || false}
                      onChange={() =>
                        setIsDeliveryRequired(!isDeliveryRequired)
                      }
                    />
                  }
                  label={isDeliveryRequired ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                />
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                {isDeliveryRequired && (
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກທີ່ຢູ່</InputLabel>
                    <Select
                      id="deliveryAddress"
                      value={deliveryAddress?.id || ""} // Corrected: Use the object directly
                      label="ເລືອກທີ່ຢູ່"
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        const selectedAddress =
                          deliveryAddresses.find(
                            (address) => address.id === selectedId
                          ) || null;
                        setDeliveryAddress(selectedAddress);
                      }}
                      required={true}
                    >
                      {deliveryAddresses &&
                        deliveryAddresses.map((address) => (
                          <MenuItem key={address.id} value={address.id}>
                            {address.title} - {address.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={0} margin={1}>
              <Grid item xl={6} md={6} xs={12}>
                <InputLabel>
                  ຝາກນຳຂົນສົ່ງຕໍ່ ເຊັ່ນ​ ຮຸ່ງອາລຸນ, ອະນຸສິດ, ມີໄຊ ...
                </InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!customer} // Disable if customer is null/undefined
                      color="secondary"
                      checked={isForwardRequired || false}
                      onChange={() => setIsForwardRequired(!isForwardRequired)}
                    />
                  }
                  label={isForwardRequired ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                />
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                {isForwardRequired && (
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກທີ່ຢູ່</InputLabel>
                    <Select
                      id="forwardAddress"
                      value={forwardAddress?.id || ""} // Use id for comparison
                      label="ເລືອກທີ່ຢູ່"
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        const selectedAddress =
                          forwardAddresses.find(
                            (address) => address.id === selectedId
                          ) || null;
                        setForwardAddress(selectedAddress);
                      }}
                      required={true}
                    >
                      {forwardAddresses &&
                        forwardAddresses.map((address) => (
                          <MenuItem key={address.id} value={address.id}>
                            {address.logisticCompany} - {address.branch}{" "}
                            {address.description}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            {/* Sub-Customer Option */}
            {!isAgentUser && (
              <Grid item xl={3} md={6} xs={6}>
                <InputLabel>ລູກຄ້າຂອງຕົວແທນ</InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={isSubCustomer || false}
                      onChange={() => setIsSubCustomer(!isSubCustomer)}
                    />
                  }
                  label={isSubCustomer ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                />
              </Grid>
            )}
            {/* Additional Agent Info for Sub-Customers */}
            {!isAgentUser && isSubCustomer && (
              <>
                <Grid item xl={3} md={6} xs={6}>
                  <InputLabel>ສົ່ງແຈ້ງຕົວແທນແລ້ວ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={agentWhatsappStatus || false}
                        onChange={() =>
                          setAgentWhatsappStatus(!agentWhatsappStatus)
                        }
                      />
                    }
                    label={agentWhatsappStatus ? "ສົ່ງແລ້ວ" : "ຍັງບໍ່ໄດ້ສົ່ງ"}
                  />
                </Grid>
                <Grid item xl={3} md={6} xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                    <Select
                      id="agent"
                      value={agentId || ""}
                      label="ເລືອກຕົວແທນ Agent"
                      onChange={(event) => setAgentId(event.target.value)}
                      required
                    >
                      {agentList &&
                        agentList.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {id} - {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xl={3} md={6} xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Final Shipping Fee</InputLabel>
                    <OutlinedInput
                      value={finalShippingFee}
                      type="number"
                      label="Final Shipping Fee"
                      id="finalShippingFee"
                      onChange={(e) => setFinalShippingFee(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xl={12} md={12} xs={12} mb={2}>
              <Divider />
            </Grid>
            {/* Tracking Number */}
            <Grid item xl={3} md={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tracking Number</InputLabel>
                <OutlinedInput
                  label="Tracking Number"
                  id="tracking"
                  value={tracking}
                  required
                  onChange={(e) => setTracking(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      setTracking(getScanText(tracking));
                      document.getElementById("shippingFee").select();
                      document.getElementById("shippingFee").focus();
                      e.preventDefault();
                    }
                  }}
                />
              </FormControl>
            </Grid>
            {/* Order ID */}
            <Grid item xl={3} md={3} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Order ID</InputLabel>
                <OutlinedInput
                  type="text"
                  label="Order ID"
                  value={orderId}
                  id="orderId"
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* Amount */}
            <Grid item xl={3} md={3} xs={3}>
              <FormControl fullWidth>
                <InputLabel>Amount</InputLabel>
                <OutlinedInput
                  type="number"
                  label="Amount"
                  value={amount}
                  id="amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* Shipping Fee / Final Shipping Fee */}
            {isAgentUser ? (
              <Grid item xl={3} md={3} xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Final Shipping Fee</InputLabel>
                  <OutlinedInput
                    value={finalShippingFee}
                    type="number"
                    label="Final Shipping Fee"
                    id="finalShippingFee"
                    onChange={(e) => setFinalShippingFee(e.target.value)}
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item xl={3} md={3} xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Shipping Fee</InputLabel>
                  <OutlinedInput
                    value={shippingFee}
                    type="number"
                    label="Shipping Fee"
                    id="shippingFee"
                    onChange={(e) => setShippingFee(e.target.value)}
                    disabled={isAgentUser}
                  />
                </FormControl>
              </Grid>
            )}
            {/* Description */}
            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={5}
                  label="Description"
                  value={description}
                  id="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
            </Grid>
            {/* Remark */}
            <Grid item xl={12} md={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  multiline
                  maxRows={5}
                  label="Remark"
                  value={remark}
                  id="remark"
                  onChange={(e) => setRemark(e.target.value)}
                />
              </FormControl>
            </Grid>

            {/* Quantity */}
            {/* <Grid item xl={4} md={4} xs={4}>
              <FormControl fullWidth>
                <InputLabel>Quantity</InputLabel>
                <OutlinedInput
                  type="number"
                  value={quantity}
                  label="Quantity"
                  id="quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={3}
            mb={2}
            mr={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid item xl={3} md={3} xs={4}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  color="secondary"
                >
                  Save
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PackageEditDialog;
