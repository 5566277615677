import React, { useEffect, useState } from "react";
import Header from "components/Header";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import FlexBetween from "components/FlexBetween";
import { useTheme } from "@emotion/react";
import { GetStationDetailById, GetFinalStationList } from "api/stations";
import { GetRouteList, GetRouteListByStation } from "api/routes";
import { GetSumPackagesForBulkMove, scanPackage } from "api/packages";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import {
  copyTextToClipboard,
  getScanText,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { defaultStation, user } from "localStorage";

const BulkMove = () => {
  const theme = useTheme();
  //   const [stationList, setStationList] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [rows, setRows] = useState(null);
  const [succesCount, setSuccessCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [routeList, setRouteList] = useState([]);
  const [routeId, setRouteId] = useState("");
  const [stationList, setStationList] = useState([]);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const _user = user();
  const _defaultStation = defaultStation();
  // const stationList = _user.stations;

  function RefreshPackagesList(routeId, station, search) {
    setRows([]);
    setIsLoading(true);
    GetSumPackagesForBulkMove(routeId, station, search).then((res) => {
      if (res.status === 200) {
        // console.log(res.data);
        setRows(res.data);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (selectedStation) {
      RefreshPackagesList(routeId, selectedStation.id, search);
      GetRouteListByStation(selectedStation.id).then((res) => {
        if (res.status === 200 && res.data) {
          setRouteList(res.data);
          setRouteId(res.data[0].id);
        }
      });
    }
  }, [selectedStation, routeId, succesCount, search]);

  useEffect(() => {
    setSelectedStation(_defaultStation);
    GetRouteList().then((res) => {
      setRouteList(res.data);
    });

    GetFinalStationList().then((res) => {
      setStationList(res.data);
      if (res && res.status === 200) {
        let arr = res.data;
        let validStation = [];
        if (Array.isArray(arr)) {
          arr.forEach((finalStation) => {
            // console.log("finalStation", finalStation);
            _user.stations.forEach((userStation) => {
              // console.log("userStation", userStation);
              if (finalStation.id === userStation.id)
                validStation.push(finalStation);
            });
          });
        }
        if (validStation) setStationList(validStation);
      }
      setSelectedStation(_defaultStation);
    });
  }, []);

  const handleBulkMove = async (data, selectedStation) => {
    setIsProcessing(true);
    const packageDetail = data.packageDetail;
    let success = 0;
    let failed = 0;
    let errMsg = ""; // Initialize the error message variable

    for (let index = 0; index < packageDetail.length; index++) {
      let actionType = "bulkMove";
      try {
        const res = await scanPackage(
          getScanText(packageDetail[index].tracking),
          selectedStation.id,
          actionType
        );

        if (res && res.status === 200) {
          console.log(`[ success ] - ${res.data.tracking}`);
          success++;
        } else {
          console.log(`[ failed!! ] - ${res.data.message}`);
          failed++;
          errMsg += `Failed to move ${packageDetail[index].tracking}: ${res.data.message}\n`; // Append error message
        }
      } catch (error) {
        console.error(
          `Error during scanPackage for ${packageDetail[index].tracking}:`,
          error
        );
        failed++;
        errMsg += `Failed to move ${packageDetail[index].tracking}: ${
          error.message || "Unknown error"
        }\n`; // Append error message for catch
      }
    }

    let customer = data._id.customer;
    console.log(success, failed);

    if (success === packageDetail.length) {
      setSuccessCount(succesCount + 1);
      playCorrectSound();
      Swal.fire({
        icon: "success",
        title: `Success!`,
        text: `${success} packages for ${customer.name} has been moved to ${selectedStation.id}`,
        timer: 3000,
      });
    } else if (success >= 1 && success < packageDetail.length) {
      playAlertSound();
      Swal.fire({
        title: `Fails ${failed} out of ${packageDetail.length} item(s)`,
        icon: "warning",
        text: errMsg, // Display the error message
      });
    } else {
      playIncorrectSound();
      Swal.fire({
        title: `All ${packageDetail.length} item(s) failed`,
        icon: "error",
        text: errMsg, // Display the error message
      });
    }
    setIsProcessing(false);
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => params.row._id.customer._id,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.7,
      valueGetter: (params) => params.row._id.customer.name,
    },
    {
      field: "whatsapp",
      headerName: "Whatsapp",
      flex: 1,
      valueGetter: (params) => params.row._id.customer.whatsapp,
      // renderCell: (cellValues) => {
      //   return (
      //     <IconButton
      //       variant="outlined"
      //       onClick={() => {
      //         copyTextToClipboard(cellValues.row.customer.whatsapp);
      //       }}
      //       sx={{ "&:hover": { color: theme.palette.warning.main } }}
      //     >
      //       {cellValues.row.customer.whatsapp}
      //     </IconButton>
      //   );
      // },
    },

    {
      field: "smsMessage",
      headerName: "Message",
      flex: 3,
      hide: !isNonMobile,
    },
    {
      field: "totalPackages",
      headerName: "item(s).",
      flex: 0.5,
    },
    {
      field: "totalShippingFee",
      headerName: "Fee",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "copy",
      headerName: "Copy",
      flex: 0.5,
      hide: !isNonMobile,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              copyTextToClipboard(cellValues.row.smsMessage);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ContentCopyIcon />
          </IconButton>
        );
      },
    },
    {
      field: "move",
      headerName: "Move",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            disabled={isProcessing}
            onClick={() => {
              handleBulkMove(cellValues.row, selectedStation);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <MoveUpIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box m="0 1rem">
      <Grid container spacing={1}>
        <Grid mb={1} item xl={12} md={12} xs={12}>
          <Header
            title="Bulk Move"
            subtitle="Move parcels to the next station, group by customer."
          />
        </Grid>
        <Grid item xl={6} md={6} xs={12}>
          <FlexBetween>
            {/* Filtering section */}
            <Grid container spacing={2}>
              <Grid item xl={3} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Select station</InputLabel>
                  <Select
                    label="Select station"
                    value={selectedStation ? selectedStation.id : ""}
                    onChange={(e) => {
                      GetStationDetailById(e.target.value).then((res) => {
                        if (res.status === 200) {
                          setSelectedStation(res.data);
                        }
                      });
                    }}
                    required
                  >
                    {stationList &&
                      stationList.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {id} ({name})
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={3} md={6} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Route</InputLabel>
                  <Select
                    id="route"
                    value={!routeId ? "" : routeId}
                    label="Route"
                    onChange={(event) => setRouteId(event.target.value)}
                    required
                  >
                    {routeList &&
                      routeList.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {id} ({name})
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </FlexBetween>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid mt={"-0.5rem"} item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            height="77vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              loading={isLoading}
              getRowId={(row) => row._id.customer._id}
              rows={rows || []}
              columns={columns}
              rowCount={(rows && rows.length) || 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              sortingMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbar }}
              componentsProps={{
                toolbar: { searchInput, setSearchInput, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BulkMove;
