import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import TabsNavigator from "components/TabsNavigator";
import NewPackage from "./newPackage";
// import PackageListItems from "./packageListItems";
import PackageListByStation from "./packageListByStation";
import PackageList from "./packageList";

const Packages = () => {
  const tabItems = [
    {
      text: "New Package",
      component: <NewPackage />,
    },
    {
      text: "Package list",
      component: <PackageList />,
    },
    {
      text: "Stock",
      component: <PackageListByStation />,
    },
  ];

  return (
    <Box m="0 1rem">
      <Header title="Packages" />
      <TabsNavigator tabItems={tabItems} />
    </Box>
  );
};

export default Packages;
